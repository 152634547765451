import React, { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import CustomTitleAccount from "./customTitleAccount";
import CustomPasswordInput from "./customPasswordInput";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateUserPassword } from "../../redux/reducer/userAddressReducer";

const AccountPasswordSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = () => {
    if (newPassword !== confirmPassword) {
      alert(t("Passwords do not match!"));
      return;
    }

    dispatch(updateUserPassword({ oldPassword, newPassword }));
    setIsEditing(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    alert("password updated successfully!")
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setIsEditing(true);
  };

  return (
    <div className="w-full">
      <CustomTitleAccount
        title={t("ChangePassword")}
        icon={<LockClosedIcon className="w-8 h-8 text-black" />}
        isEditing={isEditing}
        onClick={isEditing ? handleSave : null}
      />

      <div className="mt-4 space-y-4">
        <div className="w-1/2">
          <CustomPasswordInput
            id="password1"
            label={t("OldPassword")}
            placeholder={t("EnterYourOldPassword")}
            value={oldPassword}
            onChange={handleInputChange(setOldPassword)}
          />
        </div>
        <div className="flex w-full gap-3">
          <CustomPasswordInput
            id="password2"
            label={t("NewPassword")}
            placeholder={t("EnterNewPassword")}
            value={newPassword}
            onChange={handleInputChange(setNewPassword)}
          />
          <CustomPasswordInput
            id="password3"
            label={t("ConfirmPassword")}
            placeholder={t("ConfirmNewPassword")}
            value={confirmPassword}
            onChange={handleInputChange(setConfirmPassword)}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountPasswordSection;
