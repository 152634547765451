import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomTitleTerms from './customTitleTerms';
import CustomTermPoints from './customTermPoints';

const ContainerTerms = () => {
  const { t, i18n } = useTranslation();

  // Check if the current language is Arabic
  const isRTL = i18n.language === 'ar';

  return (
    <div className='py-3  md:py-8'>
      <div
        className={`max-container ${isRTL ? 'rtl' : 'ltr'}`} // Set direction
        dir={isRTL ? 'rtl' : 'ltr'} // Add dir attribute for accessibility
      >
        <div
          className={` ${
            isRTL ? 'text-right' : 'text-left'
          } mx-3 px-5 lg:px-20 shadow-lg py-10 lg:py-20 space-y-2 flex flex-col gap-y-5`}
        >
          {/* Privacy Policy */}
          <CustomTitleTerms title={t('privacyPolicyTitle')} />
          <CustomTermPoints title={t('privacyPolicyPoint1')} />
          <CustomTermPoints title={t('privacyPolicyPoint2')} />

          {/* Consent for Data Transfer */}
          <CustomTitleTerms title={t('consentDataTransferTitle')} />
          <CustomTermPoints title={t('consentDataTransferPoint')} />

          {/* Data Collection and Usage */}
          <CustomTitleTerms title={t('dataCollectionTitle')} />
          <CustomTermPoints title={t('dataCollectionPoint1')} />
          <CustomTermPoints title={t('dataCollectionPoint2')} />
          <CustomTermPoints title={t('dataCollectionPoint3')} />
          <CustomTermPoints title={t('dataCollectionPoint4')} />
          <CustomTermPoints title={t('dataCollectionPoint5')} />

          {/* Data Usage and Disclosure */}
          <CustomTitleTerms title={t('dataUsageDisclosureTitle')} />
          <CustomTermPoints title={t('dataUsageDisclosurePoint1')} />
          <CustomTermPoints title={t('dataUsageDisclosurePoint2')} />
          <CustomTermPoints title={t('dataUsageDisclosurePoint3')} />
          <CustomTermPoints title={t('dataUsageDisclosurePoint4')} />

          {/* Data Removal Requests */}
          <CustomTitleTerms title={t('dataRemovalTitle')} />
          <CustomTermPoints title={t('dataRemovalPoint')} />

          {/* Privacy Policy Updates */}
          <CustomTitleTerms title={t('policyUpdatesTitle')} />
          <CustomTermPoints title={t('policyUpdatesPoint')} />
        </div>
      </div>
    </div>
  );
};

export default ContainerTerms;
