import React,{useState,useEffect} from 'react';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import CustomTitle from '../sharedComponents/customTitle';
import CustomShoppngCardDetials from './customShoppingCardData';
import CustomShoppingCard from './customShoppingCrad';
import imageTrukey from '../../assets/images/truckeyShoppingImage.png';
import imageFlag from '../../assets/images/turkey.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ShoppingTrukeySection =()=>{
    const  navigate = useNavigate();
    const {t} =useTranslation();
    const [direction, setDirection] = useState(
            localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
          );
          
          useEffect(() => {
            const handleStorageChange = () => {
              setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
            };
          
            window.addEventListener("storage", handleStorageChange);
            return () => window.removeEventListener("storage", handleStorageChange);
          }, []);
          
          useEffect(() => {
            const language = localStorage.getItem("language");
            setDirection(language === "ar" ? "rtl" : "ltr");
          }, [localStorage.getItem("language")]); 
          
    const handleClick = () => {
   navigate('/ChooseCategoryTrukeyPage')
   
     }
    return(
      <div className='' dir={direction}>
          <div className="pt-5 pb-10 max-container">

          <CustomTitle
          title={t('shoppingTurkeySection.title')}
          />
          <div className='md:flex'>
          <CustomShoppngCardDetials
            title={t('shoppingTurkeySection.cardTitle2')}
            image={imageFlag}
            icon={ArchiveBoxIcon}
            onClick={handleClick}
            imageTitle={t('shoppingTurkeySection.flagImageTitle')}
            children={t('shoppingTurkeySection.buttonLabel')}
            subTitle={t('shoppingTurkeySection.cardSubtitle2')}
            area="67dfef4a100f542a2c700c04" />
        
            <CustomShoppingCard
            image={imageTrukey}
            title={t('shoppingTurkeySection.cardTitle1')}
            subTitle={t('shoppingTurkeySection.cardSubtitle1')} />
            
          </div>

          </div>
      
      </div>
    )
}

export default ShoppingTrukeySection;