import React from "react";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

const CopyableListInput = ({ label, title }) => {
  // Function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied: ${text}`);
  };

  return (
    <div className="md:w-[80%]">
      <div className="mb-4 text-lg font-bold text-black w-full">{label}</div>
      <div className="flex items-center mb-4 justify-between gap-4 border bg-white border-gray-500 w-full">
        {/* Title on the right */}
        <h2 className="flex-grow p-2 rounded-md bg-white text-black">{title}</h2>

        {/* Copy Button */}
        <button
          onClick={() => copyToClipboard(title)} // Copy the `title` prop
          className="p-2 text-gray-600 hover:text-black bg-white rounded-md"
        >
          <ClipboardDocumentIcon className="w-5 h-5 text-gray-600 hover:text-black" />
        </button>
      </div>
    </div>
  );
};

export default CopyableListInput;
