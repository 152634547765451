import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddressesByUserId, updateUserAddress } from "../../redux/reducer/userAddressReducer";
import { MapPinIcon } from "@heroicons/react/24/outline";
import CustomTitleAccount from "./customTitleAccount";
import CustomInfoInput from "./customInfoInput";
import { useTranslation } from "react-i18next";

const AccountAddressSection = () => {
  const [fullAddress, setFullAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [originalData, setOriginalData] = useState({});
  const [addressId, setAddressId] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { addresses, loading, error } = useSelector((state) => state.userAddress);

  useEffect(() => {
    dispatch(getAddressesByUserId());
  }, [dispatch]);

  useEffect(() => {
    if (addresses) {
      const address = addresses?.user?.addresses[0];
      setFullAddress(address?.fullAddress || "");
      setCity(address?.city || "");
      setCountry(address?.country || "");
      setState(address?.state || "");
      setPostalCode(address?.postalCode || "");
      setOriginalData({
        fullAddress: address?.fullAddress,
        city: address?.city,
        country: address?.country,
        state: address?.street,
        postalCode: address?.postalCode,
      });
      setAddressId(address?._id);
    }
  }, [addresses]);

  const handleSave = () => {
    if (!addressId) return;
    
    const updatedData = { fullAddress, city, country, state, postalCode };
    if (JSON.stringify(updatedData) === JSON.stringify(originalData)) {
      alert(t("No changes detected!"));
      return;
    }

    dispatch(updateUserAddress({ addressId, updatedData }))
      .unwrap()
      .then(() => {
        alert(t("Address updated successfully!"));
        setIsEditing(false);
        setOriginalData(updatedData);
      })
      .catch((error) => console.error("Error updating address:", error));
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setIsEditing(true);
  };

  return (
    <div className="flex flex-col gap-5">
      <CustomTitleAccount
        title={t("CurrentAddress")}
        icon={<MapPinIcon className="w-8 h-8 text-black" />}
        isEditing={isEditing}
        onClick={isEditing ? handleSave : null}
      />

      <div className="flex">
        <CustomInfoInput
          id="fullAddress"
          label={t("FullAddress")}
          type="text"
          placeholder={t("FullAddress")}
          value={fullAddress}
          onChange={handleInputChange(setFullAddress)}
          icon={<i className="fas fa-map-marked-alt"></i>}
        />
      </div>

      <div className="flex gap-3">
        <CustomInfoInput
          id="country"
          label={t("Country")}
          type="text"
          placeholder={t("Country")}
          value={country}
          onChange={handleInputChange(setCountry)}
          icon={<i className="fas fa-globe"></i>}
        />
        <CustomInfoInput
          id="city"
          label={t("City")}
          type="text"
          placeholder={t("City")}
          value={city}
          onChange={handleInputChange(setCity)}
          icon={<i className="fas fa-city"></i>}
        />
      </div>

      <div className="flex gap-3">
        <CustomInfoInput
          id="postalCode"
          label={t("PostalCode")}
          type="text"
          placeholder={t("PostalCode")}
          value={postalCode}
          onChange={handleInputChange(setPostalCode)}
          icon={<i className="fas fa-mail-bulk"></i>}
        />
        <CustomInfoInput
          id="state"
          label={t("State/Province")}
          type="text"
          placeholder={t("State")}
          value={state}
          onChange={handleInputChange(setState)}
          icon={<i className="fas fa-road"></i>}
        />
      </div>

      {loading && <p>{t("Loading...")}</p>}
      {error && <p className="text-red-500">{t("Error")}: {error}</p>}
    </div>
  );
};

export default AccountAddressSection;
