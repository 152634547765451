import React, { useState } from 'react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';

const CustomBlockCard = ({ image, title }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave} 
            className="flex flex-col items-center justify-center mt-5 text-center cursor-pointer group"
        >
            <div className="relative w-full flex justify-center">
                {isHovered && (
                    <ChevronDoubleDownIcon className="w-6 h-6 md:w-8 md:h-8 text-black absolute top-[-50px]" />
                )}
            </div>
            <img
                src={image}
                alt="image"
                className="max-h-[131px] "
            />
         <div
  className={`px-4 mt-2 w-full h-[65px] lg:h-[90px] duration-300 group-hover:bg-black  bg-custom-yellow flex items-center justify-center`}
>
  <h2 className={`text-sm md:text-base group-hover:text-white text-black font-semibold`}>
  {title.replace(/\n/g, '<br />')}
  </h2>
</div>

        </div>
    );
};

export default CustomBlockCard;
