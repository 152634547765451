import  React from 'react';
import Header from '../component/sharedComponents/header';
import FooterUser from '../component/sharedComponents/footer';
import ShippingInsuranceContainer  from '../component/ShippingInsurance/containerShoppingInsurance';
import CustomIntroSection from '../component/explainBuyPage/customIntroSection';
import { useTranslation } from 'react-i18next';

const ShippingInsurancePage =()=>{
      const {t} = useTranslation();
      return(
        <div>
        <Header/>
          <div className='mt-[55px] md:mt-[54px] lg:mt-[75px]'>
                <CustomIntroSection
                  title={t("shippingInsurance.title")}
                  />
          <ShippingInsuranceContainer />
          </div>
        <FooterUser/>
        </div>
    )
}

export default ShippingInsurancePage;