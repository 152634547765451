import React from 'react';
import Header from '../component/sharedComponents/header';
import FooterUser from '../component/sharedComponents/footer';
import OTPForm from '../component/auth/otpForm';

const OTPPage =()=>{
    return(
        <div>
            <Header/>
            <div className='mt-[55px] md:mt-[56px] lg:mt-[75px]  px-3 py-3 md:py-7 md:px-0  md:h-[50vh] lg:h-[70vh] flex flex-col justify-center'>
                <OTPForm />
            </div>
            <FooterUser/>
        </div>
    )
}

export default OTPPage;