import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const CustomCheckBox = ({ title, isChecked, onChange }) => {
  return (
    <div className="mb-[0.125rem] block min-h-[1.5rem]" dir="">
      {/* Custom Checkbox */}
      <label
        htmlFor={title}
        className="relative inline-flex flex-row-reverse items-center mt-3 cursor-pointer me-3"
      >
        <span
          className={`w-5 h-5 border-2 rounded-custom flex items-center justify-center transition duration-200
            ${isChecked ? 'border-primary-color bg-primary-color' : 'border-gray-500 bg-transparent'}`}
        >
          {/* FontAwesome Checkmark Icon */}
          <FontAwesomeIcon
            icon={faCheck}
            className={`w-3 h-3 text-white transition-opacity duration-200 ${isChecked ? 'opacity-100' : 'opacity-0'}`}
          />
        </span>

        {/* Title text next to checkbox */}       </label>

      {/* Hidden input for checkbox interaction */}
      <input
        type="checkbox"
        id={title}
        className="hidden"
        checked={isChecked}
        onChange={onChange}
      />
              <span className="mr-2 text-gray-700">{title}</span>

    </div>
  );
};

export default CustomCheckBox;
