import React,{useState,useEffect} from 'react';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import imageFlagTrukey from '../../assets/images/turkey.png';
import imageEroupa from '../../assets/images/oroba.png';
import yellowBoxLeft from "../../assets/images/boxOne.png"
import yellowBoxRight from "../../assets/images/boxTwo.png"
import { useTranslation } from 'react-i18next';
import CustomShoppngCardDetials from '../home/customShoppingCardData';
import { useNavigate } from 'react-router-dom';
const TurkishAndEuropean = ()=>{
    const navigate = useNavigate();
    const {t} =useTranslation();
    const [direction, setDirection] = useState(
            localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
          );
          
          useEffect(() => {
            const handleStorageChange = () => {
              setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
            };
          
            window.addEventListener("storage", handleStorageChange);
            return () => window.removeEventListener("storage", handleStorageChange);
          }, []);
          
          useEffect(() => {
            const language = localStorage.getItem("language");
            setDirection(language === "ar" ? "rtl" : "ltr");
          }, [localStorage.getItem("language")]); 
          
    const handleClick = () => {
   navigate('/ChooseCategoryTrukeyPage')
   
     }
    return(
      <div className='' dir={direction}>
          <div className="pt-5 pb-10 max-container">

          {/* <CustomTitle
          title={t('shoppingTurkeySection.title')}
          /> */}
          <div className='flex flex-col items-center justify-around md:flex-row'>
          <CustomShoppngCardDetials
            title={t('shoppingTurkeySection.cardTitle2')}
            image={imageFlagTrukey}
            icon={ArchiveBoxIcon}
            onClick={handleClick}
            imageTitle={t('shoppingTurkeySection.flagImageTitle')}
            children={t('shoppingTurkeySection.buttonLabel')}
            subTitle={t('shoppingTurkeySection.cardSubtitle2')} 
            area="67dfef4a100f542a2c700c04"/>
          
          <img 
            src={direction === "rtl"? yellowBoxLeft : yellowBoxRight} 
            alt="Background" 
            className={`top-0 left-0 w-32 md:w-44 `} 
          />

        <CustomShoppngCardDetials
          title={t('shoppingEuropeSection.cardTitle1')}
          image={imageEroupa}
          onClick={handleClick}
          icon={ArchiveBoxIcon}
          imageTitle={t('shoppingEuropeSection.flagImageTitle')}
          children={t('shoppingEuropeSection.buttonLabel')}
          subTitle={t('shoppingEuropeSection.cardSubtitle1')} 
          area="67dfef98100f542a2c700c08"
          />
              
          </div>

          </div>
      
      </div>
    )
}

export default TurkishAndEuropean;