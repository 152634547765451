import React, { useEffect,useState } from "react";
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import CustomTitle from '../sharedComponents/customTitle';
import CustomShoppngCardDetials from './customShoppingCardData';
import CustomShoppingCard from './customShoppingCrad';
import imageEroupa from '../../assets/images/shoppingOrouba.png';
import imageFlag from '../../assets/images/oroba.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BoxSection4 from "../../assets/images/BoxSection4.png"

const ShoppingEuropeSection =()=>{
    const Navigate = useNavigate();
    const {t} = useTranslation();
    const [direction, setDirection] = useState(
        localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
      );
      
      useEffect(() => {
        const handleStorageChange = () => {
          setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
        };
      
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
      }, []);
      
      useEffect(() => {
        const language = localStorage.getItem("language");
        setDirection(language === "ar" ? "rtl" : "ltr");
      }, [localStorage.getItem("language")]); 
      
    const handleClick =() => {
        Navigate('/ChooseCategoryEroupaPage')
    }
    return(
        <div className='relative' dir={direction}>
          <img 
            src={BoxSection4} 
            alt="Background" 
            className="absolute right-0 transform -translate-y-1/2 top-1/2 w-14 md:w-20"
          />
            <div className="relative pt-5 pb-10 max-container">

            <CustomTitle
            title={t('shoppingEuropeSection.title')} 
            />
            <div className='md:flex'>
            
            <CustomShoppingCard
            image={imageEroupa}
            title={t('shoppingEuropeSection.cardTitle2')}
            subTitle={t('shoppingEuropeSection.cardSubtitle2')} />
            <CustomShoppngCardDetials
        title={t('shoppingEuropeSection.cardTitle1')}
        image={imageFlag}
        onClick={handleClick}
        icon={ArchiveBoxIcon}
        imageTitle={t('shoppingEuropeSection.flagImageTitle')}
        children={t('shoppingEuropeSection.buttonLabel')}
        subTitle={t('shoppingEuropeSection.cardSubtitle1')} 
        area="67dfef98100f542a2c700c08" />
            </div>

            </div>
            
        </div>
    )
}

export default ShoppingEuropeSection;