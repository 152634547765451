import React from "react";

const CustomCardImportantServices =({title ,image})=>{
    return (
<div className="items-center pb-5 bg-black">
    <img
    src={image}
    alt="image"
    className="w-full p-5"
    />
    <p className="text-white ">
        {title}
    </p>
</div>
    )
}
export default CustomCardImportantServices;