import React from "react";
import Header from "../component/sharedComponents/header";
import FooterUser from "../component/sharedComponents/footer";
import RegistrationForm from "../component/auth/registerationForm";
const RegistrationPage =()=>{
    return(
        <div>
            <Header/>
            <div className="mt-[55px] md:mt-[56px] lg:mt-[75px]  px-3 py-3 md:py-7 md:px-0  md:h-auto lg:h-[90vh] flex flex-col justify-center">
                <RegistrationForm/>
            </div>
            <FooterUser/>
        </div>
    )
}

export default RegistrationPage;