import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchWebsitesByIds } from "../../redux/reducer/europaWebsitesMainReducer";
import CustomBrandsCard from "./customBrandsCard";
import CustomLines from "../home/customLines";
import CustomBigText from "../sharedComponents/customBigText";
import { useTranslation } from "react-i18next";
import CustomTitle from "../sharedComponents/customTitle";
import CustomLogosCard from './customLogosCard';
import outletImage from '../../assets/images/outlet.png';
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from '@heroicons/react/24/outline';

const ShoppingBrandsWebsitesEroupa = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [direction, setDirection] = useState(
    localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
  );  
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleStorageChange = () => {
      setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
    };
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  useEffect(() => {
    const ids = Array.isArray(location.state?.websites) ? location.state.websites : [];
    if (!ids.length) {
      setError("No IDs provided for this category.");
      setLoading(false);
      return;
    }

    dispatch(fetchWebsitesByIds(ids))
      .then((result) => {
        if (result.payload && result.payload.length) {
          const groupedCategories = result.payload.reduce((acc, website, index) => {
            const category = website.category?.ar || website.category?.en || "Uncategorized";
            if (!acc[category]) acc[category] = [];
            acc[category].push({ ...website, id: website.id || website._id || index });
            return acc;
          }, {});
          setCategories(groupedCategories.Uncategorized);
        } else {
          setError("No websites found for the provided IDs.");
        }
      })
      .catch((error) => setError("Error fetching websites: " + error.message))
      .finally(() => setLoading(false));
      if (Object.keys(categories).length > 0 && !selectedCategory) {
        const firstCategory = Object.keys(categories)[0];
        setSelectedCategory(firstCategory);
      }
        }, [dispatch, location.state?.websites]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setTimeout(() => {
      sectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };
    // scroll 
    const sliderRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
  
    const handleScroll = (direction) => {
      if (sliderRef.current) {
        const scrollAmount = 150;
        const maxScroll = sliderRef.current.scrollWidth - sliderRef.current.clientWidth;
        let newPosition = direction === "right" ? scrollPosition + scrollAmount : scrollPosition - scrollAmount;
  
        if (newPosition >= maxScroll) newPosition = 0;
        if (newPosition < 0) newPosition = maxScroll;
  
        sliderRef.current.scrollTo({ left: newPosition, behavior: "smooth" });
        setScrollPosition(newPosition);
      }
    };
  
    useEffect(() => {
      const interval = setInterval(() => {
        handleScroll("right");
      }, 3000);
      return () => clearInterval(interval);
    }, [scrollPosition]);
  

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl font-semibold text-gray-600">Loading websites...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl font-semibold text-red-600">{error}</p>
      </div>
    );
  }

  if (!Object.keys(categories).length) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl font-semibold text-gray-600">No websites available.</p>
      </div>
    );
  }
  return (
    <div className='' dir={direction}>
      <div className="flex flex-col items-center justify-center pb-5 text-center max-container">
        <CustomLines />
        <CustomBigText title={t("shopping_choices_title")} />
        <p className="my-8 text-gray-700">{t("shopping_experience")}</p>

        <div className="relative w-full overflow-hidden">
          <button className="absolute z-10 p-2 duration-300 transform -translate-y-1/2 bg-white rounded-full shadow-md cursor-pointer left-2 top-1/2 hover:bg-custom-yellow hover:text-white" onClick={() => handleScroll("left")}>  
            <ChevronDoubleLeftIcon className=" h-7 w-7" />
          </button>

          <div ref={sliderRef} className="flex overflow-x-auto scroll-smooth whitespace-nowrap no-scrollbar" style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}>
            {Object.keys(categories).map((category) => {
              const image = categories[category].category.img;
              const title =
                i18n.language === "ar"
                  ? categories[category].category.name.ar || "فئة غير معروفة"
                  : categories[category].category.name.en || "Unknown Category";

              return (
                <div key={category} className="min-w-[175px] lg:min-w-[250px] mx-2">
                  <CustomBrandsCard
                    title={title}
                    image={image}
                    onCardClick={() => handleCategoryClick(category)}
                  />
                </div>
              );
            })}
          </div>

          <button className="absolute z-10 p-2 duration-300 transform -translate-y-1/2 bg-white rounded-full shadow-md cursor-pointer right-2 top-1/2 hover:bg-custom-yellow hover:text-white" onClick={() => handleScroll("right")}>  
            <ChevronDoubleRightIcon className="w-7 h-7" />
          </button>
        </div>


        {selectedCategory && (
          <div className="flex flex-col w-full pb-5 mt-20" ref={sectionRef}>
            <div className="flex items-center w-full">
              {/* Left Divider Line */}
            <div className="bg-black h-[2px] flex-1"></div>

            {/* Center Title */}
            <CustomBigText title={t("BrandTitle")} className="mx-4 text-black" />

            {/* Right Divider Line */}
            <div className="bg-black h-[2px] flex-1"></div>
          </div>
          <div className="grid items-center grid-cols-2 gap-3 px-3 mt-10 gap-y-7 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
            {categories[selectedCategory]?.category?.brand?.map((website, index) => {
              const title =
                i18n.language === "ar"
                  ? website.name?.ar || "موقع غير مسمى"
                  : website.name?.en || "Unnamed Website";
              return (
                <CustomLogosCard
                  key={website.id || index}
                  title={title}
                  image={website.img }
                  onCardClick={() => {
                    if (website && website.link) {
                      navigate("/IFramePage", { state: { iframeSrc: website.link } });
                      setTimeout(() => window.location.reload(), 0);
                    } else {
                      alert("Cannot navigate. The website link is missing or invalid.");
                    }
                  }}
                />
              );
            })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppingBrandsWebsitesEroupa;
