import React, { useRef, useState, useEffect } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useTranslation } from 'react-i18next';

const CountryDropdown = ({ textColor, title, onSelect }) => {
  const buttonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const { t, i18n } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(t("calculateShippingSection.selectArea"));

  const [countries, setCountries] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, []);

  const [direction, setDirection] = useState(
          localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
        );
        
        useEffect(() => {
          const handleStorageChange = () => {
            setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
          };
        
          window.addEventListener("storage", handleStorageChange);
          return () => window.removeEventListener("storage", handleStorageChange);
        }, []);
        
        useEffect(() => {
          const language = localStorage.getItem("language");
          setDirection(language === "ar" ? "rtl" : "ltr");
        }, [localStorage.getItem("language")]); 
        

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        
        const language = localStorage.getItem("language");
        const formattedCountries = data.map((country) => ({
          id: country.cca2,
          name: language === "ar" ? country.translations.ara.common : country.name.common,
          englishName: country.name.common, 
          flag: country.flags.svg,
        }));
        setCountries(formattedCountries.sort((a, b) => a.name.localeCompare(b.name)));
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, [i18n.language]);

  useEffect(() => {
    setSelectedItem(t("calculateShippingSection.selectArea"));
  }, [i18n.language]);

  
  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <Menu as="div" className="relative inline-block w-full" dir={direction}>
      <div>
        <h4 className="mb-2 text-lg text-gray-800">{title}</h4>
        <Menu.Button
          ref={buttonRef}
          className={`flex items-center justify-between rounded-md bg-white border border-gray-300 p-3 w-full  text-gray-700`}
          dir={direction}
        >
          <span className="text-gray-400 md:mx-1">{selectedItem}</span>
          <ChevronDownIcon className="w-6 h-6 text-black" />
        </Menu.Button>
      </div>

      <Menu.Items
        className="absolute right-0 z-40 mt-1 overflow-y-auto bg-white border border-gray-300 rounded-md shadow-lg focus:outline-none max-h-60"
        style={{ width: buttonWidth }}
      >
        <div className="sticky top-0 p-2 bg-white border-b">
          <input
            type="text"
            placeholder={t("Search ")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full px-3 py-2 text-gray-700 bg-white border rounded-md"
          />
        </div>
        {filteredCountries.map((country) => (
        <Menu.Item key={country.id}>
          {({ active }) => (
            <button
              onClick={() => {
                setSelectedItem(country.name);
                onSelect(country.englishName);
              }}
              className={`flex items-center px-4 py-2 text-sm text-start w-full ${
                active ? "bg-gray-100 text-gray-900" : "text-gray-700"
              }`}
            >
              <img src={country.flag} alt={country.name} className="h-auto w-7 me-2" />
              {country.name}
            </button>
          )}
        </Menu.Item>
      ))}
      </Menu.Items>
    </Menu>
  );
};

export default CountryDropdown;