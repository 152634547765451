    import React ,{ useRef } from "react";
    import Header from "../component/sharedComponents/header";
    import AskSection from '../component/home/askSection';
    import FooterUser from '../component/sharedComponents/footer';
    import ImportantServices from '../component/home/importantServices';
    import  CustomReviewsSection from '../component/home/clientsReviewsSection';
    import CalcalateShippingSection from '../component/home/calcalateShippingPriceSection';
    import AllShippingSection from '../component/home/allShippingSection';
    import ServicesBuySection from '../component/home/servicesBuySection';
    import ShoppingEuropeSection from '../component/home/shoppingEuropeSection';
    import ShoppingTrukeySection from '../component/home/shoppingTruckeySection';
    import ChooseItemsSection from '../component/home/chooseItemsSection';
    import ScrollerSection from '../component/home/scrollerSection';
    import main from "../assets/images/main.png"
import FadeInWhenVisible from "../component/animation/FadeInWhenVisible";
    const Home =()=>{

        const importantServicesRef = useRef(null);

        const scrollToImportantServices = () => {
            if (importantServicesRef.current) {
                importantServicesRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        };

        return (
            <div className="relative">
                
                {/* <img src={main}/> */}
                <Header />
                <ScrollerSection scrollToImportantServices={scrollToImportantServices} />
                <FadeInWhenVisible><ChooseItemsSection /></FadeInWhenVisible>
                <FadeInWhenVisible><ShoppingTrukeySection /></FadeInWhenVisible>
                <FadeInWhenVisible><ShoppingEuropeSection /></FadeInWhenVisible>
                <FadeInWhenVisible><ServicesBuySection /></FadeInWhenVisible>
                <FadeInWhenVisible><AllShippingSection /></FadeInWhenVisible>
                <FadeInWhenVisible><CalcalateShippingSection /></FadeInWhenVisible>
                <FadeInWhenVisible><CustomReviewsSection /></FadeInWhenVisible>
                <FadeInWhenVisible>
                    <ImportantServices ref={importantServicesRef} />
                </FadeInWhenVisible>
                <FadeInWhenVisible><AskSection /></FadeInWhenVisible>
                <FooterUser/>
            </div>
        )
    }
    export default Home;