import React from "react";
import { useTranslation } from "react-i18next";

const CustomTermPoints = ({ title }) => {
  const { i18n } = useTranslation();

  // Check if the current language is Arabic
  const isRTL = i18n.language === "ar";

  return (
    <div className={` rounded-md ${isRTL ? "text-right" : "text-left"}`}>
      <ul className={`list-none list-inside`}>
        <li className="text-lg leading-relaxed text-black ">{title}</li>
      </ul>
    </div>
  );
};

export default CustomTermPoints;
