import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch and useSelector
import { getUserBoxOrders } from "../../redux/reducer/shipandShopOrdersReducer"; 
import { HomeIcon, ViewColumnsIcon, BookOpenIcon, UserIcon, StarIcon, ComputerDesktopIcon, SpeakerWaveIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import AccountInfoSection from "./accountInfoSection";
import AccountPasswordSection from "./accountPasswordSection";
import AccountAddressSection from "./accountAddressSection";
import PaymentForm from "./accountPaymentSection";
import CustomBigButton from "./customBigButton";
import CartChooseButton from "../cartAddress/customButtonChooseCart";
import ImportantAdver from "../controlAccount/importantAdver";
import BoxSection from "../controlAccount/boxSection";
import TitleAndIcon from "../controlAccount/titleAndIcon";
import BlockedItems from "../controlAccount/blockedItems";
import ItemsBuyMeSection from "../buyForMe/itemsSection";
import OrdersSection from "../ordersForBuyForMe/ordersSection";
import FinishedOrders from "../finishedOrders/finishedOrdersSection";
import CustomOrders from "../myOrders/CustomOrder";
import ListOfBoxCardOrders from "../boxDetials/listOfBoxOrdersCart";
import CopyLink from '../iFrame/copyLink';
import CustomBuyForMeCardList from  '../ordersForBuyForMe/listofCardsOrdersBuyForMe';
import BoxChooseButton from '../boxDetials/customButtonBox';
import { useTranslation } from "react-i18next";
import CopyLinkWithoutBackground from '../iFrame/copyLinkWithoutBackground';
import { getAddressesByUserId } from "../../redux/reducer/userAddressReducer";
const SidebarUser = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("AccountUserSection");
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Retrieve orders from the Redux store
  const orders = useSelector(state => state.shipAndShop.orders); // Assuming you store orders in state.shipAndShop.orders
  const orderCount = orders ? orders.length : 0; // Get the number of orders

  useEffect(() => {
    // Dispatch action to get user orders when component mounts
    // dispatch(getUserBoxOrders());
  }, [dispatch]);
  
  const handleMenuItemClick = (key) => {
    setSelectedMenuItem(key);
    setMenuOpen(false);
  };
  const [direction, setDirection] = useState(
        localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
      );
      
      useEffect(() => {
        const handleStorageChange = () => {
          setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
        };
      
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
      }, []);
      
      useEffect(() => {
        const language = localStorage.getItem("language");
        setDirection(language === "ar" ? "rtl" : "ltr");
      }, [localStorage.getItem("language")]); 
  
  return (
    <div className="max-container">  
      <div className="flex-col px-4 md:flex " dir={direction}>
        <div className="w-full md:flex">
          {/* Sidebar Section */}
          <div className="w-1/4">
            <div className="hidden md:block">
              <div className="flex items-center justify-around w-full">
                <div className="w-1 h-20 mx-1 bg-black"></div>
                <div className="w-1 h-20 mx-1 bg-black"></div>
              </div>
            </div>

            {/* Hamburger Icon for Mobile */}
            <div className="flex items-center justify-between p-4 md:hidden">
              <button onClick={() => setMenuOpen(!menuOpen)} className="text-black">
                {menuOpen ? (
                  <XMarkIcon className="w-7 h-7" />
                ) : (
                  <Bars3Icon className="w-7 h-7" />
                )}
              </button>
            </div>

            {/* Drawer Menu for Mobile */}
            <div
              className={`fixed top-0 ${direction === "rtl" ?"left-0" :"right-0"} z-10 w-full h-full mt-[57px] bg-white shadow-lg transform transition-all duration-300 ease-in-out ${menuOpen ? "translate-x-0" : "-translate-x-full"} md:hidden`}
            >
              <ul className="px-4 mt-2 space-y-2">
                <li className="group"> 
                  <button onClick={() => setMenuOpen(!menuOpen)} className="p-2 text-black">
                    <XMarkIcon className="w-7" />
                  </button>
                </li>
                {[{ label: t("AccountUserSection"), icon: HomeIcon, key: "AccountUserSection" },
                  { label: t("ControlBoxSection"), icon: ViewColumnsIcon, key: "ControlBoxSection" },
                  { label: t("CartChooseButton"), icon: BookOpenIcon, key: "CartChooseButton" },
                  { label: t("ListOfBoxCardOrders"), icon: UserIcon, key: "ListOfBoxCardOrders" },
                  { label: t("ItemsBuyMeSection"), icon: StarIcon, key: "ItemsBuyMeSection" },
                  { label: t("Company"), icon: StarIcon, key: "Company" },
                  { label: t("FinishedOrders"), icon: ComputerDesktopIcon, key: "FinishedOrders" },
                  { label: t("CustomOrders"), icon: SpeakerWaveIcon, key: "CustomOrders" }]
                  .map((item) => (
                    <li
                      key={item.key}
                      className={`flex items-center px-4 py-2  text-black hover:bg-custom-gradient-div cursor-pointer ${selectedMenuItem === item.key ? "bg-custom-yellow" : ""}`}
                      onClick={() => handleMenuItemClick(item.key)}
                    >
                      <item.icon className="w-5 h-5 me-2" />
                      {item.label}
                      {/* Notification Badge */}
                      {item.key === "ListOfBoxCardOrders" && orderCount > 0 && (
                        <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 rounded-full">
                          {orderCount}
                        </span>
                      )}
                    </li>
                  ))}
              </ul>
            </div>

            {/* Menu for Desktop */}
            <div className="hidden md:block">
              <div className="flex flex-col w-full p-2 bg-white rounded-md shadow-md h-fit">
                <ul className="space-y-2">
                  {[{ label: t("AccountUserSection"), icon: HomeIcon, key: "AccountUserSection" },
                    { label: t("ControlBoxSection"), icon: ViewColumnsIcon, key: "ControlBoxSection" },
                    { label: t("CartChooseButton"), icon: BookOpenIcon, key: "CartChooseButton" },
                    { label: t("ListOfBoxCardOrders"), icon: UserIcon, key: "ListOfBoxCardOrders" },
                    { label: t("ItemsBuyMeSection"), icon: StarIcon, key: "ItemsBuyMeSection" },
                    { label: t("Company"), icon: StarIcon, key: "Company" },
                    { label: t("FinishedOrders"), icon: ComputerDesktopIcon, key: "FinishedOrders" },
                    { label: t("CustomOrders"), icon: SpeakerWaveIcon, key: "CustomOrders" }]
                    .map((item) => (
                      <li
                        key={item.key}
                        className={`flex items-center px-4 py-2 text-black hover:bg-custom-gradient-div cursor-pointer ${selectedMenuItem === item.key ? "bg-custom-yellow" : ""}`}
                        onClick={() => handleMenuItemClick(item.key)}
                      >
                        <item.icon className="w-5 h-5 me-2" />
                        {item.label}
                        {/* Notification Badge */}
                        {item.key === "ListOfBoxCardOrders" && orderCount > 0 && (
                          <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 rounded-full">
                            {orderCount}
                          </span>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          {/* Content Section */}
          <div className="md:w-3/4 ">
            {selectedMenuItem === "AccountUserSection" && (
              <div className="flex flex-col justify-between max-h-[1000px] min-h-[500px] mt-10 md:ms-10 md:mt-20">
                <AccountInfoSection />
                <AccountPasswordSection />
                <AccountAddressSection />
                <CustomBigButton />
                {/* <PaymentForm /> */}
              </div>
            )}
            {selectedMenuItem === "ControlBoxSection" && (
            <div className="mt-10 md:ms-10 md:mt-20">
                <TitleAndIcon />
                <BoxSection />
                <ImportantAdver />
                <BlockedItems />
              </div>
            )}
            {selectedMenuItem === "CartChooseButton" && 
              <div className="mt-10 md:ms-10 md:mt-20">
            <CartChooseButton />
            </div>
            }
            {selectedMenuItem === "ListOfBoxCardOrders" && 
              <div className="mt-10 md:ms-10 md:mt-20">
            <BoxChooseButton />
            </div>
            }
            {selectedMenuItem === "ItemsBuyMeSection" && (
                <div className="mt-10 md:ms-10 md:mt-20">
                <ItemsBuyMeSection />
                
                <CopyLinkWithoutBackground/>
              </div>
            )}
            {selectedMenuItem === "Company" &&
            <div className="mt-10 md:ms-10 md:mt-20">
            <CustomBuyForMeCardList/>
            </div>
            }
            {selectedMenuItem === "FinishedOrders" &&
              <div className="mt-10 md:ms-10 md:mt-20">
            <FinishedOrders />
            </div>
            }
            {selectedMenuItem === "CustomOrders" && 
            <div className="mt-10 md:ms-10 md:mt-20">
            <CustomOrders />
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarUser;
