import React, { useRef, useState, useEffect } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const PhoneCodeDropdown = ({ textColor, title, onSelect }) => {
  const buttonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    const fetchPhoneCodes = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const codes = data
          .map((country, index) => ({
            id: index,
            name: `+${country.idd?.root?.replace("+", "") || ""}${country.idd?.suffixes ? country.idd.suffixes[0] : ""}`,
            country: country.name.common,
            flag: country.flags.svg,
          }))
          .filter((item) => item.name !== "+"); // Filter out entries without a phone code
        
        setPhoneCodes(codes);
        setSelectedItem(codes[0]);
      } catch (error) {
        console.error("Error fetching phone codes:", error);
      }
    };

    fetchPhoneCodes();
  }, []);

  const filteredCodes = phoneCodes.filter((item) =>
    item.country.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Menu as="div" className="relative inline-block w-full mb-3">
      <div>
        <h4 className="text-lg text-gray-800 ">{title}</h4>
        <Menu.Button
          ref={buttonRef}
          className={`flex items-center justify-between rounded-md bg-white border border-gray-300 p-3 w-full font-medium text-gray-700`}
        >
          {selectedItem && (
            <span className="flex items-center gap-2">
              <img src={selectedItem.flag} alt={selectedItem.country} className="w-6 h-4" />
              {selectedItem.name}
            </span>
          )}
          <ChevronDownIcon className="w-6 h-6 text-black" />
        </Menu.Button>
      </div>

      <Menu.Items
        className="absolute z-40 mt-1 bg-white border border-gray-300 rounded-md shadow-lg start-0 focus:outline-none"
        style={{ width: buttonWidth * 2, maxHeight: "150px", overflowY: "auto" }}
      >
        <div className="sticky top-0 p-2 bg-white border-b">
          <input
            type="text"
            placeholder="Search country..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full px-3 py-2 text-gray-700 bg-white border rounded-md"
          />
        </div>
        {filteredCodes.map((item) => (
          <Menu.Item key={item.id}>
            {({ active }) => (
              <button
              onClick={() => {
                setSelectedItem(item);
                onSelect(item.name); // إرجاع كود الهاتف فقط كـ string
              }}
              className={`flex items-center gap-2 px-4 py-2 text-sm text-start w-full ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
            >
              <img src={item.flag} alt={item.country} className="w-6 h-4" />
              {item.name} ({item.country})
            </button>
            
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default PhoneCodeDropdown;
