import React, { useRef, useState, useEffect } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useTranslation } from 'react-i18next';

const CustomDropdownCountry = ({  menuItems, textColor, title, onSelect }) => {
  const buttonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth); // Get the button's width
    }
  }, []);
      const { t ,i18n} = useTranslation();

    const [direction, setDirection] = useState(
        localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
      );
      
      useEffect(() => {
        const handleStorageChange = () => {
          setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
        };
      
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
      }, []);
      
      useEffect(() => {
        const language = localStorage.getItem("language");
        setDirection(language === "ar" ? "rtl" : "ltr");
      }, [localStorage.getItem("language")]); 
      
  return (
    <Menu as="div" className="relative inline-block w-full mb-3" dir={direction}>
      <div>
        <h4 className="mb-2 text-lg text-gray-800" >
          {title}
        </h4>
        <Menu.Button
          ref={buttonRef}
          className={`flex items-center justify-between rounded-md bg-white border border-gray-300 p-2 w-full font-medium text-gray-700 `}
        >
          <span className="">{t("calculateShippingSection.selectCountry")}</span>
          <ChevronDownIcon className="w-6 h-6 text-black" />
        </Menu.Button>
      </div>

      <Menu.Items
        className="absolute z-40 mt-1 overflow-y-auto bg-white border border-gray-300 rounded-md shadow-lg end-0 focus:outline-none max-h-60"
        style={{ width: buttonWidth }}
      >
        {menuItems.map((item) => (
          <Menu.Item key={item.id}>
            {({ active }) => (
              <button
                onClick={() => {
                  setSelectedItem(item.name);
                  onSelect(item); // Pass selected item back to the parent
                }}
                className={`block px-4 py-2 text-sm text-start w-full ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
              >
                {item.name}
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default CustomDropdownCountry;
