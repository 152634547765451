import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from '../component/sharedComponents/header';
import ShoppingBrandsWebsitesEroupa from '../component/chooseShopping/shoppingBrandsWebsitesEurpa';
import CustomIntroSection from '../component/explainBuyPage/customIntroSection';
import FooterUser from '../component/sharedComponents/footer';
const ChooseShoppingEroupaPage =()=>{
    const location = useLocation();
    const { name } = useParams();
  
    // Optional effect to log or verify state updates
    useEffect(() => {
      if (!location.state?.websites) {
        console.error("No websites found in state.");
      }
    }, [location.state]);
  
    return (
      <div key={name} className="flex flex-col justify-between min-h-screen">
          <Header/>
            <div className="mt-[55px] md:mt-[75px]mt-[75px]">
              <ShoppingBrandsWebsitesEroupa/>
            </div>
          <FooterUser/>
        </div>  
    )
}

export default ChooseShoppingEroupaPage;