import React ,{useEffect,useState} from 'react';
import {ChevronDoubleDownIcon ,InboxIcon} from '@heroicons/react/24/outline'
import CustomLines from "./customLines";
import CustomBigText from "../sharedComponents/customBigText";
import image from '../../assets/images/serviceBuyImage.png';
import CustomFlags from '../../component/sharedComponents/customFlags';
import imageTurkey from '../../assets/images/turkey.png';
import imageOroba from '../../assets/images/oroba.png';
import imageWorld from '../../assets/images/world.png';
import CustomButton from "../sharedComponents/customButton";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const ServicesBuySection =()=>{
  const {t} =useTranslation();
  const navigate = useNavigate();
  
  const handleBuyForMe = () => {
    navigate('/ExplainBuyPage', { replace: false });
    window.location.reload(); // Force reload after navigation
  };
  const [direction, setDirection] = useState(
      localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
    );
    
    useEffect(() => {
      const handleStorageChange = () => {
        setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
      };
    
      window.addEventListener("storage", handleStorageChange);
      return () => window.removeEventListener("storage", handleStorageChange);
    }, []);
    
    useEffect(() => {
      const language = localStorage.getItem("language");
      setDirection(language === "ar" ? "rtl" : "ltr");
    }, [localStorage.getItem("language")]); 
  
    
    return (
      <div className='pb-3 bg-black'>
        <div className="flex flex-col-reverse justify-around md:flex-row max-container" dir={direction}>
        <div className='flex items-center justify-center md:w-1/2'>
        <img
          src={image}
          alt="image"
          className="max-h-[600px]"
          />
        </div>
            <div className="flex flex-col justify-between max-h-[550px] min-h-[500px] md:min-h-auto">
            <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-between" >
            {/* Line 1 */}
            <div className={`bg-yellow-50 w-[4px] h-[60px] md:h-[100px]  ${direction ==="rtl"?"ml-24 md:ml-40" :"mr-24 md:mr-40"}`}></div>
            {/* Space Between */}
            <div className="bg-yellow-50 w-[4px] h-[60px] md:h-[100px] "></div>
          </div>
    <CustomBigText title={t('servicesBuySection.title')} className="" />
  </div>

  <div>
    <p className="text-sm font-medium text-center text-white ">
    {t('servicesBuySection.description')}
      </p>
  </div>
  <div className="flex items-center justify-center gap-8">
        <div className="flex flex-col items-center justify-center">
          <ChevronDoubleDownIcon className="w-6 h-6 mb-2 text-white" />
          <img
            src={imageTurkey}
            alt="flag"
            className="w-20"
          />
          <div className="inline-block px-8 py-2 bg-custom-yellow ">
            <h2 className="text-sm font-bold text-black ">{t('servicesBuySection.turkishStores')}</h2>
          </div>
        </div>
        <h2 className="text-2xl font-semibold text-white ">{t("allShippingSection.and")}</h2>
        <div className="flex flex-col items-center justify-center">
        <ChevronDoubleDownIcon className="w-6 h-6 mb-2 text-white" />
        <img
          src={imageOroba}
          alt="flag"
          className="w-20 "
        />
        <div className="inline-block px-8 py-2 bg-custom-yellow ">
          <h2 className="text-sm font-bold text-black">{t('servicesBuySection.europeanStores')}</h2>
        </div>
      </div>
        </div>
        <div className="flex flex-col items-center justify-center pb-5 md:pb-0">
        <div className=''>
            <p className="text-sm font-medium text-center text-white ">
            {t('servicesBuySection.descriptionTwo')} </p>
        </div>
          <ChevronDoubleDownIcon className="w-6 h-6 my-3 text-white" />
          <CustomButton 
            children={t('servicesBuySection.buttonLabel')}
            icon={InboxIcon} onClick={handleBuyForMe}
          />
        </div>

        </div>
        
        </div>
        
      </div>
    )
}
export default ServicesBuySection;