import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMainTurkishWebsites } from "../../redux/reducer/turkishWebsitesMainReducer";
import CustomLines from "../home/customLines";
import CustomBigText from "../sharedComponents/customBigText";
import CategoryCard from "./cardCategory";
// import ImageSix from "../../assets/images/categorySix.png";
// import ImageFive from "../../assets/images/categoryFive.png";
// import ImageFour from "../../assets/images/categoryFour.png";
// import ImageThree from "../../assets/images/categoryThree.png";
// import ImageTwo from "../../assets/images/categoryTwo.png";
// import ImageOne from "../../assets/images/categoryOne.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChooseCategoryTrukeySection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
 const {t ,i18n} = useTranslation();
  // const staticImages = [ImageOne, ImageTwo, ImageThree, ImageFour, ImageFive, ImageSix];
  const { mainTurkishWebsites, status, error } = useSelector((state) => state.websites);
  const [direction, setDirection] = useState(
    localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
  );
  
  useEffect(() => {
    const handleStorageChange = () => {
      setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
    };
  
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);
  
  useEffect(() => {
    const language = localStorage.getItem("language");
    setDirection(language === "ar" ? "rtl" : "ltr");
  }, [localStorage.getItem("language")]); 
  
  useEffect(() => {
    if (status === "idle" || !mainTurkishWebsites.length) {
      dispatch(fetchMainTurkishWebsites());
    }
  }, [dispatch, status, mainTurkishWebsites.length]);

  if (status === "loading") return <p>Loading...</p>;
  if (status === "failed") return <p className="text-red-500">Error: {error}</p>;
  if (!mainTurkishWebsites || mainTurkishWebsites.length === 0) return <p>No websites available</p>;

  const handleCategoryClick = (id, websites) => {
    if (!websites || websites.length === 0) {
      console.error("No websites available for this category.");
      return;
    }

     navigate(`/ChooseShoppingTrukeyPage/${encodeURIComponent(id)}`, { state: { websites } });

     // Force a full page reload
     window.location.reload();
  };
  // console.log(mainTurkishWebsites)
  return (
    <div className=''>
      <div className="flex flex-col items-center justify-center text-center max-container">
        <CustomLines />
        <CustomBigText title={t("categoryTitle")}/>
        <div className="px-3 py-10">
          <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-cols-6" dir={direction}>
            {mainTurkishWebsites.map((website, index) => {
              const title = i18n.language === "ar" ? website.title?.ar : website.title?.en;
              return (
                <div
                  key={website.id || website._id || index} // Fallback to index if no id is available
                  className="flex flex-col items-center cursor-pointer"
                  onClick={() => handleCategoryClick(website._id, website.category)}
                >
                  <CategoryCard
                    title={title || "Unnamed Website"}
                    image={website.img}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseCategoryTrukeySection;
