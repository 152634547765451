import React,{useState,useEffect} from "react";
import CustomListBrands from './customListBrands';
import CustomFlags from "../sharedComponents/customFlags";
import CustomBlackButton  from '../sharedComponents/customBlackButton';
import { useTranslation } from "react-i18next";
const CustomShoppngCardDetials = ({ image, title, imageTitle, subTitle, children, onClick, icon: Icon ,area }) => {
  const {t} = useTranslation();
  const [direction, setDirection] = useState(
          localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
        );
        
        useEffect(() => {
          const handleStorageChange = () => {
            setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
          };
        
          window.addEventListener("storage", handleStorageChange);
          return () => window.removeEventListener("storage", handleStorageChange);
        }, []);
        
        useEffect(() => {
          const language = localStorage.getItem("language");
          setDirection(language === "ar" ? "rtl" : "ltr");
        }, [localStorage.getItem("language")]); 
        
        
   return (
    <div className="flex flex-col items-center justify-center pb-5 space-y-6 text-center md:w-2/5" dir={direction}>
      {/* Title */}
      <p className="mt-4 font-medium text-gray-700">
        {title}
      </p>

      {/* Custom List Brands */}
      <CustomListBrands id={area} />

      {/* Centered Arabic Word */}
      <div className="flex items-center justify-center">
        <h2 className="text-lg font-medium text-black">
        {t("fromText")}
        </h2>
      </div>

      {/* Custom Flags */}
      <CustomFlags
        image={image}
        title={imageTitle}
      />

      {/* Subtitle */}
      <p className="mt-4 font-medium text-gray-700">
        {subTitle}
      </p>

      {/* Button */}
   <CustomBlackButton 
   onClick={onClick}
   children={children}
   icon ={Icon}
   />
    </div>
  );
};

export default CustomShoppngCardDetials;
