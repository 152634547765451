import React, { useState, useEffect, useRef } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ShoppingBagIcon  } from "@heroicons/react/24/outline";

const menuItems = {
  en: [
    { id: 1, label: 'Turkish Stores', href: '/ChooseCategoryTrukeyPage' },
    { id: 2, label: 'European Stores', href: '/ChooseCategoryEroupaPage' },
  ],
  ar: [
    { id: 1, label: 'المتاجر التركية', href: '/ChooseCategoryTrukeyPage' },
    { id: 2, label: 'المتاجر الأوروبية', href: '/ChooseCategoryEroupaPage' },
  ],
};

const CustomDropDownNavBar = ({ language = 'en' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Ensure we are fetching the right language items
  const items = menuItems[language] || menuItems.en;
  const [direction, setDirection] = useState(
      localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
    );
    
    useEffect(() => {
      const handleStorageChange = () => {
        setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
      };
    
      window.addEventListener("storage", handleStorageChange);
      return () => window.removeEventListener("storage", handleStorageChange);
    }, []);
    

    useEffect(() => {
      const language = localStorage.getItem("language");
      setDirection(language === "ar" ? "rtl" : "ltr");
    }, [localStorage.getItem("language")]);
    
  return (
    <div style={{ position: 'relative',marginLeft:0 , marginTop :0 ,marginBottom:""}} >
      <button
        ref={buttonRef}
        className="flex items-stretch text-left lg:mx-5 group "
        onClick={toggleDropdown}
        style={{
          display: 'flex',
          background: 'transparent',
          border: 'none',
          color: 'white',
        }}
        dir={direction === "rtl" ?"ltr" :"rtl"}
      >
        <ChevronDownIcon className="w-4 h-4 mt-1 text-white me-2 group-hover:text-custom-yellow" />
        <div className='flex flex-col'>
          <span className="font-medium text-white duration-300 group-hover:text-custom-yellow">
            {language === 'ar' ? 'متاجر التسوق' : 'Shopping stores'}
          </span>
          <div
            style={{ width: "50px" }}
            className={`h-[4px] mx-auto hidden lg:block mt-1 duration-300 group-hover:bg-custom-yellow`}
          ></div>
        </div>
        <ShoppingBagIcon  className="hidden w-4 h-4 mt-1 ms-1 md:block group-hover:text-custom-yellow" />
      </button>
      {isOpen && (
        <ul
          ref={dropdownRef}
          dir={direction} 
          className="z-50 w-36 rounded-custom lg:absolute lg:bg-[#1f1f1f]  lg:mt-0 mt-3"
          style={{
            listStyle: 'none',
            // margin: 0,
            top: 40,
            right: 16,
            position: 'block',
            zIndex: 10,
            // background: 'black',
            borderRadius: '5px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            color: 'white',
            // background:"#1f1f1f"
          }}
        >
          {items.map((item) => (
            <li
            // lg:hover:after:content-[''] lg:hover:after:absolute lg:hover:after:left-0 lg:hover:after:bottom-0 lg:hover:after:w-full lg:hover:after:h-[3px]  lg:hover:after:bg-custom-yellow
            className="relative flex items-center text-white duration-700 rounded cursor-pointer group "
              key={item.id}
              style={{
                padding: '10px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                color: 'white',
              }}
              onClick={() => {
                setIsOpen(false);
                window.location.href = item.href;
              }}
            >
              <span className='duration-300 group-hover:text-custom-yellow'>{item.label}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropDownNavBar;
