import React ,{useEffect, useState}from 'react';
import ImageBackground from '../../assets/images/Rectangle 42405.png';
import BoxOne from '../../assets/images/boxOne.png';
import BoxTwo from '../../assets/images/boxTwo.png';
import { useTranslation } from "react-i18next";


const CustomIntroSection = ({ title }) => {
  const {  i18n } = useTranslation();

  const [direction, setDirection] = useState(
    localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
  );
  
  useEffect(() => {
    const handleStorageChange = () => {
      setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
    };
  
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);
  
  useEffect(() => {
    const language = localStorage.getItem("language");
    setDirection(language === "ar" ? "rtl" : "ltr");
  }, [localStorage.getItem("language")]); 
  
useEffect(() => {
  const savedLanguage = localStorage.getItem("language");
  i18n.changeLanguage(savedLanguage);
  // document.documentElement.dir = savedLanguage === "en" ? "rtl" : "ltr";
}, [i18n]);

  return (
    <div className="relative w-full h-[300px]" dir={direction}>
      {/* Background Image */}
      <img
        src={ImageBackground}
        alt="Your Image Description"
        className="object-cover w-full h-full"
      />
      {/* Centered Content */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="flex items-center">
          {/* BoxOne */}
          <img
            src={direction === "rtl" ? BoxOne : BoxTwo}
            alt="box"
            className={`w-20 h-20 relative  top-[-10px] ${direction === "rtl" ? "right-[10px] md:right-[20px]" : "left-[10px] md:left-[20px]"}`}
          />
          {/* Title Section */}
          <div className="inline-block p-2 text-center md:px-8 bg-custom-yellow min-w-[210px] md:min-w-[400px]">
            <h2 className="text-lg font-bold text-black md:text-3xl">{title}</h2>
          </div>
          {/* BoxTwo */}
          <img
            src={direction === "ltr" ? BoxOne : BoxTwo}
            alt="box"
            className={`w-20 h-20 relative top-[-10px] ${direction === "ltr" ? "right-[10px] md:right-[20px]" : "left-[10px] md:left-[20px]"}`}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomIntroSection;
