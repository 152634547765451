import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, resetForgotPasswordState } from "../../redux/reducer/authReducer";
import CustomTitleWihBox from "../sharedComponents/customTitleWithBox";
import CustomInput from "./CustomInput";
import { EnvelopeIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
export default function ForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const [lang, setLang] = useState("en");
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const { loading, success, error } = useSelector((state) => state.auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotPassword({ email, lang }));
  };

  useEffect(() => {
    // Reset state when component unmounts
    return () => {
      dispatch(resetForgotPasswordState());
    };
  }, [dispatch]);
  const [direction, setDirection] = useState(
      localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
    );
    
    useEffect(() => {
      const handleStorageChange = () => {
        setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
      };
    
      window.addEventListener("storage", handleStorageChange);
      return () => window.removeEventListener("storage", handleStorageChange);
    }, []);
    

    useEffect(() => {
      const language = localStorage.getItem("language");
      setDirection(language === "ar" ? "rtl" : "ltr");
    }, [localStorage.getItem("language")]);
  return (
    <div
      className="block shadow-lg rounded-lg bg-white p-8 mx-auto md:w-2/3 max-w-[700px] " dir={direction}

    >
      <CustomTitleWihBox title={t("ForgotPassword")}/>
      <form onSubmit={handleSubmit}>
        {/* Email Input */}
        <div className="mb-6 mt-4">
          <CustomInput
            id="email"
            type="email"
            placeholder={t("EnterYourEmail")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            icon={<EnvelopeIcon />}
            required
          />
        </div>

        {/* Language Input (Optional) */}
        {/* <div className="mb-6">
          <CustomInput
            id="language"
            type="text"
            placeholder="اللغة (اختياري)"
            value={lang}
            onChange={(e) => setLang(e.target.value)}
            icon={<GlobeAltIcon />}
          />
        </div> */}

        {/* Submit Button */}
        <div className="flex justify-center mb-6">
          <button
            type="submit"
            className="block w-full rounded-lg bg-custom-yellow px-6 py-3 text-base font-medium uppercase
              text-black shadow-lg transition duration-150 ease-in-out hover:bg-primary-600 focus:outline-none"
            disabled={loading} // Disable button while loading
          >
 {loading ? t("Sending") : t("Send")}      
     </button>
        </div>

        {/* Success or Error Message */}
        {success && (
        <p className="text-center text-green-600">
          {t("EmailSentSuccess")}
        </p>
      )}
        {error && <p className="text-center text-red-600">{error}</p>}
      </form>
    </div>
  );
}
