import React from 'react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';

const CustomFlags = ({ image, title }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <ChevronDoubleDownIcon className="w-6 h-6 mb-2 text-black" />
      <img
        src={image}
        alt="flag"
        className="w-20 "
      />
      <div className="inline-block px-8 py-2 bg-custom-yellow ">
        <h2 className="text-sm font-bold text-black">{title}</h2>
      </div>
    </div>
  );
};

export default CustomFlags;
