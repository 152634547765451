import React, {useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import CustomLink from './customLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faWhatsapp,faTelegram,faTiktok ,faSnapchat} from '@fortawesome/free-brands-svg-icons';
import { MapPinIcon, EnvelopeIcon, PhoneIcon ,PaperAirplaneIcon,ChevronUpIcon  } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import kuwatFlag from "../../assets/images/flagKuawit.jpg"
import flagErupa from "../../assets/images/flagErupa.png"
import flagTrukey from "../../assets/images/turkey.png"
export default function FooterUser() {
  const { t } = useTranslation();
 const [direction, setDirection] = useState(
         localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
       );
       
       useEffect(() => {
         const handleStorageChange = () => {
           setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
         };
       
         window.addEventListener("storage", handleStorageChange);
         return () => window.removeEventListener("storage", handleStorageChange);
       }, []);
       
       useEffect(() => {
         const language = localStorage.getItem("language");
         setDirection(language === "ar" ? "rtl" : "ltr");
       }, [localStorage.getItem("language")]); 


       const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Enables smooth scrolling
        });
      };
       
  return (
    <div className='bg-black'>
      <div dir={direction} className=" max-container">
        <hr className="h-0.5 border-t-0 opacity-100 dark:opacity-50" />
        <footer className="px-3 py-4 mb-5 text-center px:custom-mobile-padding max-container">
          <div className="">
          <div className="flex flex-row flex-wrap justify-between h-full gap-y-5">
            <div className='flex flex-col justify-between w-full gap-3 lg:w-3/12 md:w-6/12 sm:w-6/12'>
              <Link to="/">
                <img src={Logo} alt="Logo" className="md:w-auto md:h-auto w-[161px] h-[60px]" />
              </Link>
              <p 
                className="text-sm font-medium text-white text-start" 
                dangerouslySetInnerHTML={{ __html: t('logo_description').replace(/\n/g, '<br />') }} 
              />
              <div className="flex items-center">
                <a href="https://api.whatsapp.com/send/?phone=%2B905301485665&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faWhatsapp} className="me-5 text-custom-yellow" />
                </a>
                <a href="https://www.tiktok.com/@markaship_?_t=8p7YWUFQXwm&_r=1" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTiktok} className="me-5 text-custom-yellow" />
                </a>
                <a href="https://www.snapchat.com/add/markaship?invite_id=Ho6TnrT4&locale=de_AT&share_id=rKrnJoZITN2d05xOyPd2Bg&xp_id=1&sid=2f42ef110d4c4b2e9c44f08b6c6ee823" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faSnapchat} className="me-5 text-custom-yellow" />
                </a>
                <a href="https://www.instagram.com/markaship_?igsh=MW5pcjk1cm9mdjhrYg%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="me-5 text-custom-yellow" />
                </a>
              </div>
            </div>

            <div className='flex flex-col justify-between w-full gap-3 lg:w-2/12 md:w-3/12'>
              <h6 className="text-xl font-bold uppercase md:mt-6 md:mb-2 text-custom-yellow text-start">{t('company')}</h6>
              {/* <div className='flex flex-col gap-6'> */}
                <div className=""><CustomLink to="/TermsAndConditions">{t('terms_and_conditions')}</CustomLink></div>
                <div className=""><CustomLink to="/PrivacyPolicyPage">{t('privacy_policy')}</CustomLink></div>
                <div className=""><CustomLink to="/CookiesPolicyPage">{t('cookies_policy')}</CustomLink></div>
                <div className=""><CustomLink to="/QualityControlAndShipmentPhotography">{t('refund_policy')}</CustomLink></div>
              {/* </div> */}
            </div>

            <div className='flex flex-col justify-between w-full gap-3 lg:w-2/12 md:w-3/12'>
              <h6 className="text-xl font-bold uppercase md:mt-6 md:my-2 md:m-0 text-custom-yellow text-start">{t('quick_links')}</h6>
                <div className=""><CustomLink to="/OurServicesPage">{t('our_services')}</CustomLink></div>
                <div className=""><CustomLink to="/ContactUsPage">{t('contact_us')}</CustomLink></div>
                <div className=""><CustomLink to="/Faq">{t('faq')}</CustomLink></div>
                <div className=""><CustomLink to="/ShippingInsurance">{t('shipping_insurance')}</CustomLink></div>
            </div>

            <div className='flex flex-col justify-between w-full gap-3 lg:w-2/12 md:w-3/12'>
              <h6 className="text-xl font-bold uppercase md:mt-6 md:my-2 md:m-0 text-custom-yellow text-start">{t('about_us')}</h6>
              {/* <div className='flex flex-col justify-between gap-6'> */}
                {/* <p className="flex items-center text-sm text-gray-500 lg:text-lg xl:text-lg ">
                  <PhoneIcon className={`w-4 h-4 mx-2 text-custom-yellow  ${direction==="rtl"?"rotate-[270deg]" :" " }`} />
                  <a href="tel:+447418351039" className="font-medium text-white duration-300 text-xxs lg:text-sm hover:text-custom-yellow">+447418351039</a>
                </p> */}
                <p className="flex items-center text-sm text-gray-500">
                  {/* <MapPinIcon className="w-4 h-4 mx-2 text-custom-yellow" /> */}
                  <img 
                      src={kuwatFlag} 
                      alt="Background" 
                      className="w-5 mx-2"
                    />
                  <a href="https://www.markaship.com/ContactUsPage" target="_blank" rel="noopener noreferrer" className="font-medium text-white duration-300 text-xxs lg:text-sm hover:text-custom-yellow">
                    {t('addressOne')}
                  </a>
                </p>
                <p className="flex items-center text-sm text-gray-500">
                  {/* <MapPinIcon className="w-4 h-4 mx-2 text-custom-yellow" /> */}
                   <img 
                      src={flagErupa} 
                      alt="Background" 
                      className="w-5 mx-2"
                    />
                  <a href="https://www.markaship.com/ContactUsPage" target="_blank" rel="noopener noreferrer" className="font-medium text-white duration-300 text-xxs lg:text-sm hover:text-custom-yellow">
                    {t('addressTwo')}
                  </a>
                </p>
                <p className="flex items-center text-sm text-gray-500">
                  {/* <MapPinIcon className="w-4 h-4 mx-2 text-custom-yellow" /> */}
                  <img 
                      src={flagTrukey} 
                      alt="Background" 
                      className="w-5 mx-2"
                    />
                  <a href="https://www.markaship.com/ContactUsPage" target="_blank" rel="noopener noreferrer" className="font-medium text-white duration-300 text-xxs lg:text-sm hover:text-custom-yellow">
                    {t('addressThree')}
                  </a>
                </p>
                <p className="flex items-center text-sm text-gray-500">
                  <EnvelopeIcon className="w-4 h-4 mx-2 text-custom-yellow" />
                  <a href="mailto:info@example.com" className="font-medium text-white duration-300 text-xxs lg:text-sm hover:text-custom-yellow">info@markaship.com</a>
                </p>
              {/* </div> */}
            </div>

            <div className='w-full gap-3 sm:w-6/12 lg:w-3/12 md:w-6/12'>
              <h6 className="text-xl font-bold uppercase  md:mt-6 md:my-2 md:m-0 text-custom-yellow text-start">{t('SubscribetToOurNewsletter')}</h6>
              <p className="mb-6 text-sm font-medium text-white text-start" 
                dangerouslySetInnerHTML={{ __html: t('Subscribet_description').replace(/\n/g, '<br />') }} 
              /> 
               <form>
                <div className="relative" dir={direction} >
                  <span className={`absolute inset-y-0 flex items-center  text-gray-900 ${direction ==="rtl" ?"left-2" :"right-2"} `}>
                    <button type='submit'>
                      <PaperAirplaneIcon class={`h-8 w-8 md:h-9 md:w-9 text-black bg-custom-yellow rounded-sm ${direction ==="rtl" ?"rotate-[180deg]" :""}`} />
                    </button>
                  </span>
                  <input
                      // id="newsletter"
                      type="email"
                      placeholder={t("Enter_your_email_address")}
                      // value="email"
                      // onChange={}
                      className={`w-full px-5 py-3 text-white text-xs sm:text-lg border border-custom-yellow rounded-sm bg-transparent 
                      placeholder:text-sm sm:placeholder:text-base`}
                    />
                  </div>
              </form>
            </div>
          </div>
          </div>
        </footer>

        
      </div>
      <div className="py-2 text-center text-white bg-[#0e0e0e]" dir={direction}>
          <footer className="my-3 max-container">
            <span dir={direction}>
              {t('footer_copyright')} 
              <a href="https://www.markaship.com/" className="mx-1 text-white hover:underline">{t('footer_brand')}</a>
              {t('footer_rights')}
              <a href="https://targetdigitalsolutions.com" className="mx-1 text-white hover:underline">{t('footer_developer')}</a>
            </span>
          </footer>
        </div>
        <div className={`fixed z-50 bottom-4 md:bottom-7  ${direction === "ltr" ?"right-4 md:right-7":"left-4 md:left-7"}`}>
          <button 
          className='p-2 rounded-full shadow-lg bg-custom-yellow'
          onClick={scrollToTop} 
          >
            <ChevronUpIcon class="h-5 w-5 md:h-7 md:w-7  text-black" />
          </button>
        </div>
    </div>
  );
}
