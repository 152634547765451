import React from 'react';

const CustomCardWithBox = ({ title, subTitle, img }) => {
  return (
    <div className="flex items-center justify-center group"> {/* Added a static height */}
      <div className="flex items-center ">
        {/* Title Section */}
        <div className="flex flex-col justify-center gap-10 px-4 py-8 border duration-300 group-hover:bg-black group-hover:text-white  text-black bg-custom-yellow md:min-h-[400px] " style={{ direction: 'rtl' }}>
          <div className='w-full '>  
            <img
              src={img}
              alt="image Service"
              className="w-auto h-auto mx-auto"
            />
          </div>
          <div >
            <h2 className="mb-5 text-lg font-bold text-center lg:text-xl">{title}</h2>
            <h2 className="text-sm font-semibold text-center ">{subTitle}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCardWithBox;
