import React, { useState ,useEffect } from "react";
import { useTranslation } from "react-i18next";
import {  useLocation } from "react-router-dom";
import CustomButton from "../sharedComponents/customButton";

const IframePage = () => {
  const location = useLocation();
  const { iframeSrc } = location.state || {}; // Extract the iframe source from state
   const { t } = useTranslation();
 
   const [direction, setDirection] = useState(
      localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
    );
    
    useEffect(() => {
      const handleStorageChange = () => {
        setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
      };
    
      window.addEventListener("storage", handleStorageChange);
      return () => window.removeEventListener("storage", handleStorageChange);
    }, []);
    
    useEffect(() => {
      const language = localStorage.getItem("language");
      setDirection(language === "ar" ? "rtl" : "ltr");
    }, [localStorage.getItem("language")]); 
  
    


  return (
    <div className="flex flex-col items-center justify-center h-[50vh] text-center px-5 max-container " dir={direction}>
      <p className="text-lg font-medium text-gray-700 mb-4 max-w-[500px]">
        {t("iframeMessage")}
      </p>
      <a
        href={iframeSrc}
        target="_blank"
        rel="noopener noreferrer"
        className="z-0 inline-flex items-center justify-center px-4 py-2 text-base font-medium text-black transition duration-300 ease-in-out transform bg-custom-yellow rounded-custom md:text-sm hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color whitespace-nowrap"
      >
        {t("iframeButton")}
        </a>
    </div>
  );
};

export default IframePage;
