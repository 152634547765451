import React,{useEffect,useState} from 'react';
import arrowLeft from '../../assets/images/arrowLeft.png';
import arrowRight from '../../assets/images/arrowRight.png';

const CustomLink = ({ to, children }) => {
  const handleClick = (e) => {
    e.preventDefault(); // Prevent React Router's SPA behavior
    window.location.href = to; // Force full page reload
  };
  const [direction, setDirection] = useState(
         localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
       );
       
       useEffect(() => {
         const handleStorageChange = () => {
           setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
         };
       
         window.addEventListener("storage", handleStorageChange);
         return () => window.removeEventListener("storage", handleStorageChange);
       }, []);
       useEffect(() => {
                const language = localStorage.getItem("language");
                setDirection(language === "ar" ? "rtl" : "ltr");
              }, [localStorage.getItem("language")]); 
              
  return (
    <a
      href={to}
      onClick={handleClick}
      className="flex items-center font-medium text-white duration-300 text-xxs lg:text-sm hover:text-custom-yellow"
    >
      <img
        src={direction === "rtl" ? arrowLeft:arrowRight}
        alt="arrow"
        className="mx-2"
      />
      {children}
    </a>
  );
};

export default CustomLink;
