import React ,{useState , useEffect} from 'react';
import CustomContactUsCard from './customContactUsCard';
import ImageflagTrukey from '../../assets/images/turkey.png';
import flagErupa from '../../assets/images/oroba.png';
import flagKuait from '../../assets/images/oroba.png';
import ContactUsFormUser from './contactUsForm';
import { useTranslation } from 'react-i18next';

const DataSection = () => {
  const { t ,i18n} = useTranslation();
const [direction, setDirection] = useState(
        localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
      );
      
      useEffect(() => {
        const handleStorageChange = () => {
          setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
        };
      
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
      }, []);
      
      useEffect(() => {
        const language = localStorage.getItem("language");
        setDirection(language === "ar" ? "rtl" : "ltr");
      }, [localStorage.getItem("language")]); 
      
  return (
    <div className=' py-3 md:py-10' dir={direction}>
      <div className='max-container'>
        <div className='md:flex mb-20 space-y-2 md:space-y-0 md:gap-x-4 mx-3 lg:mx-0'>
          <div className='w-full md:w-1/3'>
            <CustomContactUsCard
              title={t("Europa")}
              FlagImage={flagErupa}
              Email='info@markaship.com'
              phoneNumberOne='67762009102'
        visible={false}
              Location='Amalienstrasse 28/12. Vienna, 1130 / Austria / Europa '
            />
          </div>

          <div className='w-full md:w-1/3'>
            <CustomContactUsCard
              title={t("Turkey")}
              FlagImage={ImageflagTrukey}
              Email='info@markaship.com'
              phoneNumberOne='5331586084'
              phoneNumberTwo='5368346085'
              visible={true}
              Location='ODUNLUK MAH. ORTAÇ SK. NO: 11 A NILÜFER/ BURSA/ Türkiye'
            />
          </div>

          <div className='w-full md:w-1/3'>
            <CustomContactUsCard
              title={t("kuwait")}
              FlagImage={flagKuait}
              phoneNumberOne='94449324'
              phoneNumberTwo='66414164'
              Email='info@markaship.com'
              visible={true}
              Location='Kuwait / محافظة الفروانية / منطقة خيطان / مبنى مجمع هوزان'
            />
          </div>
        </div>
        
        <ContactUsFormUser />
      </div>
    </div>
  );
}

export default DataSection;
