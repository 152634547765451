import React from 'react';
import Header from '../component/sharedComponents/header';
import ContainerTerms from '../component/termsAndConditions/ContainerTerms';
import FooterUser from '../component/sharedComponents/footer';
import CustomIntroSection from '../component/explainBuyPage/customIntroSection';
import { useTranslation } from 'react-i18next';

const  TermsAndConditions =()=>{
    const {t} = useTranslation();
    return(
        <div>
          <Header/>
          <div className='mt-[55px] md:mt-[54px] lg:mt-[75px]'>
            <CustomIntroSection
              title={t("privacySection.termsAndCommitments")}
              />
          </div>
          <ContainerTerms/>
          <FooterUser/>
        </div>
    )
}

export default TermsAndConditions;