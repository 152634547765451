import React ,{useState ,useEffect} from 'react';
import CustomTitle from '../sharedComponents/customTitle';
import CustomHowItWorks from '../explainBuyPage/howItWorksCard';
import CustomArrowLeft from '../sharedComponents/customArrowLeft';
import ImageOne from '../../assets/images/buyOne.png';
import ImageTwo from '../../assets/images/buyTwo.png';
import ImageThree from '../../assets/images/buyThree.png';
import ImageFour from '../../assets/images/buyFour.png';
import ImageFive from '../../assets/images/buyFive.png';
import ImageSix from '../../assets/images/buysix.png';
import ImageSeven from '../../assets/images/buySeven.png';
import ImageEight from '../../assets/images/buyEight.png';
import ImageNine from '../../assets/images/buyNine.png';
import ImageTen from '../../assets/images/buyTen.png';
import ImageEleven from '../../assets/images/buyEleven.png';
import ImageTwelve from '../../assets/images/buyTwelve.png';
import CustomArrowDown from '../sharedComponents/customArrowDown';
import CustomArrowRight from '../sharedComponents/customArrowRight';
import { useTranslation } from 'react-i18next';

const HowItWorksSections =()=>{
    const [isVisible, setIsVisible] = useState(true);
    const {t,i18n} =useTranslation();
    const [direction, setDirection] = useState(
            localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
          );
          
          useEffect(() => {
            const handleStorageChange = () => {
              setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
            };
          
            window.addEventListener("storage", handleStorageChange);
            return () => window.removeEventListener("storage", handleStorageChange);
          }, []);
          
          useEffect(() => {
            const language = localStorage.getItem("language");
            setDirection(language === "ar" ? "rtl" : "ltr");
          }, [localStorage.getItem("language")]); 
          
      useEffect(() => {
          const savedLanguage = localStorage.getItem("language");
          i18n.changeLanguage(savedLanguage);
          // document.documentElement.dir = savedLanguage === "en" ? "rtl" : "ltr";
        }, [i18n]);
        
    const toggleVisibility = () => {
        setIsVisible((prev) => !prev);
    };
    return (
      <div className='' dir={direction}>
        <div className='max-container'>
          <CustomTitle title={t('howItWorks.title')} />
          <div className="items-center justify-center w-full mt-10 space-y-10 md:space-y-0 md:flex md:space-x-20">
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageThree}
                title={t('howItWorks.step3')}
                visible={isVisible}
              />
            </div>
            {/* end mobile */}
            <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageThree}
                title={t('howItWorks.step3')}
                // visible={isVisible}

              />
            </div>
             
            <div className="items-center justify-center hidden md:flex px-auto sm:block ">
              {direction === "rtl" ? <CustomArrowLeft /> : <CustomArrowRight />}
              
            </div>
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageTwo}
                title={t('howItWorks.step2')}
                visible={isVisible}
                />
            </div>
            {/* end mobile */}
           <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageTwo}
                title={t('howItWorks.step2')}
                />
            </div> 

            <div className="items-center justify-center hidden md:flex sm:block">
              {direction === "rtl" ? <CustomArrowLeft /> : <CustomArrowRight />}
            </div>
            <div className="flex justify-center md:w-1/3">
              <CustomHowItWorks
                image={ImageOne}
                title={t('howItWorks.step1')}
                visible={isVisible}/>
            </div>

          </div>

          <div className="items-center justify-center w-full space-y-10 md:mt-10 md:space-y-0 md:flex md:space-x-20">
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageFour}
                title={t('howItWorks.step4')}
                visible={isVisible}
              />
            </div>
            {/* end mobile */}
            
            <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageSix}
                visible={isVisible}
                title={t('howItWorks.step6')}
              />
            </div>
            <div className="items-center justify-center hidden md:flex px-auto sm:block">
              {direction === "ltr" ? <CustomArrowLeft /> : <CustomArrowRight />}
            </div>
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageFive}
                title={t('howItWorks.step5')}
                visible={isVisible}

              />
            </div>
            {/* end mobile */}
            <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageFive}
                title={t('howItWorks.step5')}
              />
            </div>
            <div className="items-center justify-center hidden md:flex px-auto sm:block">
              {direction === "ltr" ? <CustomArrowLeft /> : <CustomArrowRight />}
            </div>
            
            <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageFour}
                title={t('howItWorks.step4')}
              />
            </div>
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageSix}
                visible={isVisible}
                title={t('howItWorks.step6')}
              />
            </div>
            {/* end mobile */}
          </div>

          <div className="items-center justify-center w-full md:flex md:space-x-20 md:mt-5">  
              {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageNine}
                visible={isVisible}
                title={t('howItWorks.step9')}/>

            </div>
              {/* end mobile */}
            <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageNine}
                title={t('howItWorks.step9')}/>
            </div>
            <div className="items-center justify-center hidden md:flex sm:block">
              {direction === "rtl" ? <CustomArrowLeft /> : <CustomArrowRight />}
            </div>
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageEight}
                visible={isVisible}
                title={t('howItWorks.step8')} />
            </div>
            {/* end mobile */}
            <div className="justify-center hidden md:flex md:w-1/3 ">
              <CustomHowItWorks
                image={ImageEight}
                title={t('howItWorks.step8')} />
            </div>
            <div className="items-center justify-center hidden md:flex sm:block">
              {direction === "rtl" ? <CustomArrowLeft /> : <CustomArrowRight />}
            </div>
            <div className="flex justify-center md:w-1/3">
              <CustomHowItWorks
                image={ImageSeven}
                visible={isVisible}
                title={t('howItWorks.step7')}
              />
            </div>
          </div>

          <div className="items-center justify-center w-full md:flex md:space-x-20 md:mt-10">
            
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageTen}
                visible={isVisible}
                title={t('howItWorks.step10')} />
            </div>
            {/* end mobile */}

            <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageTwelve}
                title={t('howItWorks.step12')}
              />
            </div>
            
            <div className="items-center justify-center hidden md:flex px-auto sm:block ">
              {direction === "ltr" ? <CustomArrowLeft /> : <CustomArrowRight />}
            </div>
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageEight}
                title={t('howItWorks.step11')}
                visible={isVisible}

              />
            </div>
            {/* end mobile */}
            <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageEight}
                title={t('howItWorks.step11')}
              />
            </div>
            <div className="items-center justify-center hidden md:flex px-auto sm:block ">
              {direction === "ltr" ? <CustomArrowLeft /> : <CustomArrowRight />}
            </div>
            <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageTen}
                title={t('howItWorks.step10')} />
            </div>
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageTwelve}
                title={t('howItWorks.step12')}
              />
            </div>
            {/* end mobile */}
          </div>
        </div>
      </div>
    )
}
export default HowItWorksSections;