import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageSaudi from '../../assets/images/flagSaudi.webp';
import ImageUnitedkingdom from '../../assets/images/flagUnitedkingdom.webp';

const LanguageToggleButton = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("language") || "en");

  // Function to toggle between Arabic and English
  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ar" : "en";

    // Change language in i18next
    i18n.changeLanguage(newLanguage);
    
    // Update direction
    document.documentElement.dir = newLanguage === "ar" ? "ltr" : "rtl";

    // Save language in localStorage
    localStorage.setItem("language", newLanguage);

    // Update state
    setCurrentLanguage(newLanguage);
  };

  // Load language from localStorage when the component mounts
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en";
    i18n.changeLanguage(savedLanguage);
    document.documentElement.dir = savedLanguage === "ar" ? "rtl" : "ltr";
    setCurrentLanguage(savedLanguage);
  }, []);

  // Get the correct flag image based on the current language
  const flagImage = currentLanguage === "en" ? ImageUnitedkingdom : ImageSaudi;

  return (
    <button onClick={toggleLanguage} className="relative flex items-center justify-center "style={{marginLeft:0}}>
      {/* Flag */}
      <img
        src={flagImage}
        alt={currentLanguage === "en" ? "English Flag" : "Kuwait Flag"}
        className="w-8 h-6 md:w-10 md:h-6"
      />
    </button>
  );
};

export default LanguageToggleButton;
