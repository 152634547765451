import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserIcon } from "@heroicons/react/24/outline";
import CustomTitleAccount from "./customTitleAccount";
import CustomInfoInput from "./customInfoInput";
import { useTranslation } from "react-i18next";
import { getAddressesByUserId, updateUserById } from "../../redux/reducer/userAddressReducer";

const AccountInfoSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [userId, setUserId] = useState(null);
  const [originalData, setOriginalData] = useState({});

  const { addresses, loading, error } = useSelector((state) => state.userAddress);

  useEffect(() => {
    dispatch(getAddressesByUserId());
  }, [dispatch]);

  useEffect(() => {
    if (addresses) {
      setFirstName(addresses.user?.name || "");
      setPhone(addresses.user?.phone || "");
      setOriginalData({ name: addresses.user?.name, phone: addresses.user?.phone });
      setUserId(addresses.user?._id || "");
    }
  }, [addresses]);

  const handleSaveClick = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    if (firstName === originalData.name && phone === originalData.phone) {
      alert("No change in data!");
      setIsEditing(false);
      return;
    }

    const updatedData = { name: firstName, phone: phone };

    dispatch(updateUserById({ userId, updatedData }))
      .unwrap()
      .then(() => {
        alert("User updated successfully!");
        setIsEditing(false);
        setOriginalData(updatedData);
      })
      .catch((error) => {
        console.error("An error occurred while updating the user:", error);
      });
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setIsEditing(true);
  };

  return (
    <div className="w-full">
      <CustomTitleAccount
        title={t("AccountInformation")}
        onClick={isEditing ? handleSaveClick : null}
        icon={<UserIcon className="w-8 h-8 text-black" />}
        isEditing={isEditing}
      />

      {loading && <p className="mb-7"></p>}
      {error && <p className="text-red-500">{t("Error fetching data")}</p>}

      {!loading && !error && addresses && (
        <div className="flex gap-3">
          <CustomInfoInput
            id="firstName"
            label={t("Name")}
            type="text"
            value={firstName}
            onChange={handleInputChange(setFirstName)}
            icon={<i className="fas fa-user"></i>}
          />
          <CustomInfoInput
            id="phone"
            label={t("PhoneNumber")}
            type="text"
            value={phone}
            onChange={handleInputChange(setPhone)}
            icon={<i className="fas fa-phone"></i>}
          />
        </div>
      )}
    </div>
  );
};

export default AccountInfoSection;
