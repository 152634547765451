import React from "react";
import Header from "../component/sharedComponents/header";
import FooterUser from "../component/sharedComponents/footer";
import  ContainerOurServices from '../component/ourServices/containerOurServices';
import CustomIntroSection from "../component/explainBuyPage/customIntroSection";
import AskSection from "../component/home/askSection";
import { useTranslation } from "react-i18next";
const OurServicesPage =()=>{
    const {t} = useTranslation();
    return(
        <div>
        <Header/>
        <div className="mt-[55px] md:mt-[54px] lg:mt-[75px]">
            <CustomIntroSection
                title={t("privacySection.ourServices")}
            />
        </div>
        <ContainerOurServices/>
        <AskSection/>
        <FooterUser/>
        </div>
    )
}
export default OurServicesPage;