import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllServices } from "../../redux/reducer/ourServicesReducer"; // Adjust the path
import CustomCardWithBox from "../sharedComponents/customCardWithBox"; // Adjust the path
import CustomBigText from '../sharedComponents/customBigText';
import CustomLines from "../home/customLines";
import { useTranslation } from "react-i18next";

const ContainerOurServices = () => {
  const dispatch = useDispatch();
  const { services, status, error } = useSelector((state) => state.services); // Ensure `state.services` is correct
  const {i18n,t} = useTranslation();

  // Dispatch fetchAllServices only if services are not already fetched
  React.useEffect(() => {
    if (status === "idle") {
      dispatch(fetchAllServices());
    }
  }, [dispatch, status]);

  if (status === "loading") {
    return <p>Loading...</p>;
  }

  if (status === "failed") {
    return <p>Error: {error}</p>;
  }
  // console.log(services.services)
  return (
    <div className="">  
      <div className="flex flex-col items-center justify-center text-center max-container">
        {/* <CustomLines /> */}
        {/* <CustomBigText title={t('ProhibitedItemsTitle')} /> */}
      <div className="py-10 mx-4 lg:mx-0">
        {services.length === 0 ? (
          <p>No services available</p>
        ) : (
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
            {services.services.map((service) => {
              const title = i18n.language === "ar" ? service.title?.ar : service.title?.en;
              const shortDes = i18n.language === "ar" ? service.shortDes?.ar : service.shortDes?.en;

              return(
              <div key={service._id} className="flex">
                <CustomCardWithBox
                  title={title}
                  subTitle={shortDes}
                  img={service.img}
                />
              </div>
            )})}
          </div>
        )}
      </div>
      </div>
    </div>
  );
};

export default ContainerOurServices;
 