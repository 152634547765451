import React ,{useState , useEffect} from "react";
import CustomIntroSection from "../component/explainBuyPage/customIntroSection";
import Header from "../component/sharedComponents/header";
import BlockedItemsSection  from '../component/explainBuyPage/blockedItemsSection';
import HowItWorksSections from '../component/explainBuyPage/howItWorksSection';
import AskSection from "../component/home/askSection";
import FooterUser from "../component/sharedComponents/footer";
import { useTranslation } from "react-i18next";
import TurkishAndEuropean from "../component/explainBuyPage/TurkishAndEuropean";
const ExplainBuyPage =()=>{
    const {t} =useTranslation();
    
          
    return (
        <div className="" > 
            <Header/>
            <div className="mt-[55px] md:mt-[56px] lg:mt-[75px] ">
                <CustomIntroSection
                title={t('introSection.title')}
                />
            </div>
            <BlockedItemsSection />
            <TurkishAndEuropean />
            <HowItWorksSections/>
            <AskSection/>
            <FooterUser/>
        </div>
    )
}

export default ExplainBuyPage;