import React, { useState , useEffect} from "react";
import { useDispatch } from "react-redux";
import { loginWithOTP } from "../../redux/reducer/authReducer"; // Adjust the path as needed
import CustomTitleWihBox from "../sharedComponents/customTitleWithBox";
import CustomInput from "./CustomInput";
import { EnvelopeIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import CustomPasswordInput from "../accountUser/customPasswordInput";
import { useTranslation } from "react-i18next";
export default function OTPForm() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use the useNavigate hook
  const {t} = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();

    // Dispatch loginUser with email and password
    dispatch(loginWithOTP({ otp }))
      .unwrap() // Unwrap the action result to handle the response
      .then((response) => {
        // Save the accessToken and userId to localStorage
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("userId", response.user._id);

        // Optionally, use the custom handleClick to navigate and reload the page
        handleClick(); 

        // Show a success alert
        alert("OTP successful!");
      })
      .catch((error) => {
        // Optionally handle login errors if needed
        console.error("OTP failed:", error);
        alert("OTP failed! Please check your credentials.");
      });
  };

  const [direction, setDirection] = useState(
        localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
      );
      
      useEffect(() => {
        const handleStorageChange = () => {
          setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
        };
      
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
      }, []);
      
  
      useEffect(() => {
        const language = localStorage.getItem("language");
        setDirection(language === "ar" ? "rtl" : "ltr");
      }, [localStorage.getItem("language")]); 

  // handleClick to perform the navigation and force page reload
  const handleClick = () => {
    window.location.href = "/LoginPage"; // Redirect to the home page
  };

  return (
    <div
      className="block  p-8 mx-auto bg-white rounded-lg shadow-lg md:w-2/3 max-w-[700px]"
      dir={direction}
    >
      <CustomTitleWihBox title={t("OTP")} />
      <form onSubmit={handleSubmit}>
        <div className="mt-12 mb-12 md:flex">

          {/* Password */}
          <div className="mb-6 md:w-full md:mr-2 ">
          {/* <CustomInput /> */}
          <CustomInput
  id="otp"
  label={t("OPT")}
  placeholder={t("EnteryourOTP")}
  value={otp}
  onChange={(e) => setOtp(e.target.value)}
  icon={<LockOpenIcon />} // Optional: Add an icon prop if supported
  required
/>

          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-center mb-6">
          <button
            type="submit"
            className="block w-full px-6 py-3 text-base font-medium text-black uppercase transition duration-150 ease-in-out rounded-lg shadow-lg bg-custom-yellow hover:bg-primary-600 focus:outline-none"
          >
          {t("send")}
          </button>
        </div>

        {/* Navigation to Registration */}
      </form>
    </div>
  );
}
