import React ,{useState ,useEffect} from "react";

export default function CustomInfoInput({
  id,
  label, // Label for the input
  type = "text",
  placeholder,
  value,
  onChange,
  icon,
}) {
  const [direction, setDirection] = useState(
          localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
        );
        
        useEffect(() => {
          const handleStorageChange = () => {
            setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
          };
        
          window.addEventListener("storage", handleStorageChange);
          return () => window.removeEventListener("storage", handleStorageChange);
        }, []);
        
        useEffect(() => {
          const language = localStorage.getItem("language");
          setDirection(language === "ar" ? "rtl" : "ltr");
        }, [localStorage.getItem("language")]); 
        
        
  return (
    <div className="relative w-full ">
      {/* Arabic Label */}
      {label && (
        <label
          htmlFor={id}
          className="block mb-2 text-sm font-semibold text-gray-700"
          dir={direction} // Set the label direction
        >
          {label}
        </label>
      )}
      <div className="relative">
        {/* Optional Icon */}
        {icon && (
          <span className="absolute inset-y-0 left-0 flex items-center text-gray-900">
            {icon}
          </span>
        )}
        {/* Arabic Input Field */}
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          dir={direction} // Set the input text direction
          className="w-full px-2 py-3 text-xs text-black bg-white border border-gray-300 rounded-lg md:px-5 sm:text-lg placeholder:text-sm"
        />
      </div>
    </div>
  );
}
