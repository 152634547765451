import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../constent';
// Thunks for asynchronous operations

// 1. Upload Files
export const uploadFiles = createAsyncThunk(
  'shipAndShop/uploadFiles',
  async ({ filter, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/upload/${filter}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'File upload failed');
    }
  }
);

// 2. List Files
export const listFiles = createAsyncThunk(
  'shipAndShop/listFiles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/files`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to retrieve files');
    }
  }
);

// 3. Download File
export const downloadFile = createAsyncThunk(
  'shipAndShop/downloadFile',
  async (fileName, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/files/${fileName}`, { responseType: 'blob' });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to download file');
    }
  }
);

// 4. Delete File
export const deleteFile = createAsyncThunk(
  'shipAndShop/deleteFile',
  async (fileName, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/files/${fileName}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete file');
    }
  }
);

// 5. Get All Orders
export const getAllOrders = createAsyncThunk(
  'shipAndShop/getAllOrders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(BASE_URL);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to retrieve orders');
    }
  }
);

// 6. Create or Update Orders
export const createOrUpdateOrders = createAsyncThunk(
  'shipAndShop/createOrUpdateOrders',
  async (ordersData, { rejectWithValue }) => {
    try {
      const response = await axios.post(BASE_URL, ordersData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create or update orders');
    }
  }
);

// 7. Get Specific Order
export const getSpecificOrder = createAsyncThunk(
  'shipAndShop/getSpecificOrder',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/${orderId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to retrieve the order');
    }
  }
);

// 8. Update Specific Order
export const updateSpecificOrder = createAsyncThunk(
  'shipAndShop/updateSpecificOrder',
  async ({ orderId, updateData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}/${orderId}`, updateData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update the order');
    }
  }
);

export const updateOrderDetails = createAsyncThunk(
    "shipAndShop/updateOrderDetails",
    async ({ orderId, updateData }, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("accessToken"); // Get the token from localStorage
        if (!token) throw new Error("No access token found");
  
        const response = await axios.put(
          `${BASE_URL}/shipAndShopOrders/${orderId}`, // API endpoint with order ID
          updateData, // Payload to update the order details
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the Authorization header
            },
          }
        );
  
        return response.data; // Return the updated data if the request is successful
      } catch (error) {
        return rejectWithValue(error.response?.data || "Failed to update order details");
      }
    }
  )
// 9. Get User Box Orders
export const getUserBoxOrders = createAsyncThunk(
    'shipAndShop/getUserBoxOrders',
    async (_, { rejectWithValue }) => {
      try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("accessToken");
        if (!token) throw new Error("No access token found");
  
        // Make the API request with the token included in the headers
        const response = await axios.get(`${BASE_URL}/shipAndShopOrders/getUserBoxOrders/byUserId`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token in the Authorization header
          },
        });
        //  console.log(response.data)
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || 'Failed to retrieve box orders');
      }
    }
  );
  
// 10. Get User Counts
export const getUserCounts = createAsyncThunk(
  'shipAndShop/getUserCounts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/getUserCounts/byUserId`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to retrieve user counts');
    }
  }
);

// Slice
const shipAndShopSlice = createSlice({
  name: 'shipAndShop',
  initialState: {
    files: [],
    orders: [],
    specificOrder: null,
    userBoxOrders: [],
    userCounts: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle upload files
      .addCase(uploadFiles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadFiles.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(uploadFiles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle list files
      .addCase(listFiles.fulfilled, (state, action) => {
        state.files = action.payload;
      })
      // Handle get all orders
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.orders = action.payload;
      })
      // Handle get specific order
      .addCase(getSpecificOrder.fulfilled, (state, action) => {
        state.specificOrder = action.payload;
      })
      // Handle get user box orders
      .addCase(getUserBoxOrders.fulfilled, (state, action) => {
        state.userBoxOrders = action.payload;
      })
      // Handle get user counts
      .addCase(getUserCounts.fulfilled, (state, action) => {
        state.userCounts = action.payload;
      })
      // Handle errors
      .addMatcher(
        (action) => action.type.endsWith('rejected'),
        (state, action) => {
          state.error = action.payload;
          state.loading = false;
        }
      );
  },
});

export default shipAndShopSlice.reducer;
