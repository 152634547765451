import React from "react";
import Header from '../component/sharedComponents/header';
import DataSection from "../component/contactUs/dataSection";
import FooterUser from "../component/sharedComponents/footer";
import CustomIntroSection from "../component/explainBuyPage/customIntroSection";
import { useTranslation } from "react-i18next";

const ContactUsPage =()=>{
    const {t} = useTranslation();
    return(
        <div className="">
            <Header/>
            <div className="mt-[55px] md:mt-[54px] lg:mt-[75px]">
            <CustomIntroSection
                title={t("contactUs")}
                />
            </div>
            <DataSection/>
            <FooterUser/>
        </div>
    )
}

export default ContactUsPage;