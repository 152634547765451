import React, { useState } from 'react';
import { ChevronDoubleRightIcon  } from '@heroicons/react/24/outline';

const CustomCategoryCard = ({ title,image  }) => {

    return (
        <div 
            className="flex flex-col items-center justify-center text-center cursor-pointer md:min-w-48 group"
        >
            <img
                src={image}
                alt="image"
                className="w-full h-40"
            />
            <div
                className={"w-full py-2 group-hover:bg-black bg-custom-yellow"}
            >
                <h2 className={"flex items-center justify-center md:font-semibold text-center duration-300 group-hover:text-white text-black"}>
                    {/* {isHovered && (
                        <div className="flex ">
                            <ChevronDoubleRightIcon className="w-5 h-5 text-white" />
                            
                        </div>
                    )} */}
                    <span className="mx-1">{title}</span>
                </h2>
            </div>
        </div>
    );
};

export default CustomCategoryCard;
