import React, { useState } from 'react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
import { useTranslation } from "react-i18next";

const CustomCategoryCard = ({ image, title }) => {
     const {i18n} = useTranslation();
    
    const [isHovered, setIsHovered] = useState(false);
    const title1 = i18n.language === "ar" ? title?.ar : title?.en;
    
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave} 
            className="flex flex-col items-center justify-center w-full text-center gap-3"
        >
            <div className="relative">
                {/* {isHovered && (
                    <ChevronDoubleDownIcon className="w-6 h-6 text-black absolute top-[-30px]" />
                )} */}
            </div>
            <img
                src={image}
                alt="image"
                className=" h-auto "
            />
            <div
                className={`w-full px-4 inline-block duration-300 ${
                    isHovered ? 'bg-black' : 'bg-custom-yellow'
                }`}
            >
                
                <h2 className={`text-sm font-medium lg:text-lg py-2 duration-300 ${isHovered ? 'text-white' : 'text-black'}`}>
                    {title1}
                </h2>
            </div>
        </div>
    );
};

export default CustomCategoryCard;
