import React,{useState,useEffect} from 'react';
import {PhoneIcon}
 from '@heroicons/react/24/outline';
import image from '../../assets/images/image.png';
import CustomButton from '../sharedComponents/customButton';
import { useTranslation } from 'react-i18next';
const AskSection = () => {
  const {t} = useTranslation();
  const [direction, setDirection] = useState(
          localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
        );
        
        useEffect(() => {
          const handleStorageChange = () => {
            setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
          };
        
          window.addEventListener("storage", handleStorageChange);
          return () => window.removeEventListener("storage", handleStorageChange);
        }, []);
        
        useEffect(() => {
          const language = localStorage.getItem("language");
          setDirection(language === "ar" ? "rtl" : "ltr");
        }, [localStorage.getItem("language")]); 
        
        
  return (
    <div className="relative w-full h-[400px]" dir={direction}> {/* Adjust height as needed */}
      {/* Image */}
      <img
        src={image}
        alt="intro"
        className="object-cover w-full h-full"
      />

      {/* Overlay Content */}
      <div className="absolute inset-0 flex flex-col items-center justify-center p-4 text-center text-white bg-black bg-opacity-50">
        <h1 className="mb-4 text-3xl font-bold">{t('askSection.haveQuestion')}</h1>
        <h1 className="mb-4 text-3xl font-bold">{t('askSection.hereToHelp')}</h1>

      <a 
        className="z-0 inline-flex items-center justify-center px-4 py-2 text-base font-medium text-black transition duration-300 ease-in-out transform bg-custom-yellow rounded-custom md:text-sm hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color whitespace-nowrap "
      dir="ltr" href='https://www.markaship.com/ContactUsPage'>
        <PhoneIcon className="w-4 text-black me-2" />
        {t('askSection.contactUs')}
      </a>
      </div>
    </div>
  );
};

export default AskSection;
