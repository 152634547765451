import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HomeIcon, TruckIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";

const NavBarMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(null);
  const [menuWidths, setMenuWidths] = useState({});
  const menuRef = useRef([]);
  const closeTimeout = useRef(null);

  const menuData = {
    home: {
      name: t("home"),
      path: "/",
      icon: <HomeIcon className="hidden w-4 h-4 mr-1 lg:block" />, // Hidden on mobile, visible on md and larger
    },
    // buyForMe: {
    //   name: t("buy_for_me"),
    //   path: "/ExplainBuyPage",
    //   icon: <ShoppingCartIcon className="hidden w-4 h-4 mr-1 md:block" />, // Hidden on mobile, visible on md and larger
    // },
    // countShipping: {
    //   name: t("count_shipping"),
    //   path: "/CountShippingPage",
    //   icon: <TruckIcon className="hidden w-4 h-4 mr-1 md:block" />, // Hidden on mobile, visible on md and larger
    // },
  };

  // useEffect(() => {
  //   const widths = {};
  //   menuRef.current.forEach((ref, index) => {
  //     if (ref) {
  //       widths[Object.keys(menuData)[index]] = ref.offsetWidth;
  //     }
  //   });
  //   setMenuWidths(widths);
  // }, [menuData]);

  const handleMouseEnter = (menuName) => {
    clearTimeout(closeTimeout.current);
    setOpenMenu(menuName);
  };

  const handleMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setOpenMenu(null);
    }, 200);
  };

  const handleClick = (e, path) => {
    e.preventDefault();
    navigate(path);
    window.location.href = path;
  };

  return (
    <div className="relative mt-[-20px]">
      <div className="justify-center gap-5 mb-4 lg:py-3 lg:flex lg:mb-0">
        {Object.keys(menuData).map((key, index) => (
          <div
            key={key}
            className="relative mt-6 lg:mb-0 group"
            onMouseEnter={() => handleMouseEnter(menuData[key].name)}
            onMouseLeave={handleMouseLeave}
          >
            <a
              href={menuData[key].path}
              ref={(el) => (menuRef.current[index] = el)}
              className={`lg:flex lg:text-sm gap-1 text-white font-semibold rounded items-stretch  
                 ${
                  openMenu === menuData[key].name ? "duration-300 group-hover:text-custom-yellow" : "text-custom-yellow"
                }
              `}
              onClick={(e) => handleClick(e, menuData[key].path)}
            >
          
              {menuData[key].icon}
              {menuData[key].name}
            </a>
            <div
              style={{
                width: `${menuWidths[menuData[key].name] || 40}px`,
              }}
              className={`h-[4px] mx-auto hidden lg:block mt-1 duration-300 group-hover:bg-custom-yellow`}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavBarMenu;
