import React ,{useState , useEffect} from 'react';
import CustomLines from '../home/customLines';
import CustomBigText from '../sharedComponents/customBigText';
import CustomBlockCard from './customBlockCards';
import ImageBlockOne from '../../assets/images/blockOne.png';
import ImageBlockTwo from '../../assets/images/blockTwo.png';
import ImageBlockedThree from '../../assets/images/blockThree.png';
import ImageBlockedFour from '../../assets/images/blockFour.png';
import ImageBlockedFive from '../../assets/images/blockFive.png';
import ImageBlockedSix from '../../assets/images/no alcohol 2 1.png';
import { useTranslation } from 'react-i18next';
const BlockedItemsSection =()=>{
      const {t} = useTranslation();
      const [direction, setDirection] = useState(
         localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
       );
       
       useEffect(() => {
         const handleStorageChange = () => {
           setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
         };
       
         window.addEventListener("storage", handleStorageChange);
         return () => window.removeEventListener("storage", handleStorageChange);
       }, []);
       
       useEffect(() => {
         const language = localStorage.getItem("language");
         setDirection(language === "ar" ? "rtl" : "ltr");
       }, [localStorage.getItem("language")]); 
       
    return (
      <div className='' dir={direction}>
         <div className="flex flex-col items-center justify-center text-center max-container">
         <CustomLines />
            <div className=''>
            <CustomBigText title={t('customBigText.prohibitedItems')} />
               
            </div>
               <div className='grid items-start justify-center w-full grid-cols-2 gap-3 px-5 my-20 md:px-10 md:gap-6 lg:grid-cols-6 sm:grid-cols-3'>
                  <div className=''>
                     <CustomBlockCard
                     image={ImageBlockedSix}
                     title={t('blockedItems.allLiquids')}
                     /></div>
                  
                  <div className=''>
                  <CustomBlockCard
                     image={ImageBlockedFive}
                     title={t('blockedItems.batteries')}
                     />
                  </div>
                  <div className=''>
                  <CustomBlockCard
                     image={ImageBlockedFour}
                     title={t('blockedItems.fakeBrands')}
                     />
                  </div>
                  <div className=''>
                  <CustomBlockCard
                     image={ImageBlockedThree}
                     title={t('blockedItems.medicalSupplies')}
                     />
                  </div>
                  <div className=''>
                  <CustomBlockCard
                     image={ImageBlockTwo}
                     title={t('blockedItems.preciousMaterials')}
                     />
                  </div>
                  
                  <div className=''>
                  <CustomBlockCard
                     image={ImageBlockOne}
                     title={t('blockedItems.weaponsAndExplosives')}
                     />
                  </div>    
               </div>

            </div>
      </div>
    )
}

export default BlockedItemsSection;