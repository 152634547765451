import React, { useState } from 'react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
const CustomLogosCard = ({ image, title, link, onCardClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onCardClick} // Navigate on click
            className="flex flex-col items-center justify-center my-4 text-center cursor-pointer group" // Fixed width
        >
            <div className="relative">
                {/* {isHovered && (
                    <ChevronDoubleDownIcon className="w-6 h-6 text-black absolute top-[-30px]" />
                )} */}
            </div>
            {image && (
                <img
                    src={image}
                    alt={title || "Image"}
                    className="h-40 rounded-lg "
                />
            )}
            <div
                className={"w-full px-4 inline-block bg-custom-yellow group-hover:bg-black duration-300"}  
            >
                <h2 className={"text-sm font-semibold lg:text-lg py-4 group-hover:text-white text-black"}>
                    {title}
                </h2>
            </div>
        </div>
    );
};

export default CustomLogosCard;