import React from "react";
import Header from "../component/sharedComponents/header";
import CustomIntroSection from "../component/explainBuyPage/customIntroSection";
import FooterUser from "../component/sharedComponents/footer";
import ChooseCategoryEroupaSection from "../component/chooseCategory/chooseCategoryEroupaSection";
import { useTranslation } from "react-i18next";
const ChooseCategoryEroupaPage =()=>{
  const {t} = useTranslation();
   return (
     <div className="flex flex-col justify-between min-h-screen">
        <Header/>
        <div className="mt-[55px] md:mt-[54px] lg:mt-[75px]">
          <CustomIntroSection
          title={t('customIntro.europeanStores')}
          />
        </div>
        <ChooseCategoryEroupaSection/>
        <FooterUser/>
     </div>
   )

}

export default ChooseCategoryEroupaPage;  