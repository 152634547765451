import React from 'react';

const CustomLines = () => {
  return (
    <div className="flex items-center justify-between "dir='ltr'>
      {/* Line 1 */}
      <div className="bg-black w-[4px] h-[60px] md:h-[100px] mr-24 md:mr-40"></div>
      {/* Space Between */}
      <div className="bg-black w-[4px] h-[60px] md:h-[100px]"></div>
      
    </div>
  );
};

export default CustomLines;
