import React from 'react';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import CustomBlockCard from '../explainBuyPage/customBlockCards';
import ImageBlockOne from '../../assets/images/no alcohol 2 1.png';
import ImageBlockTwo from '../../assets/images/blockThree.png';
import ImageBlockedThree from '../../assets/images/blockFour.png';
import ImageBlockedFour from '../../assets/images/blockOne.png';
import { useTranslation } from 'react-i18next';
const BlockedItems =()=>{
      const {t} =useTranslation();
    return(
        <div className='w-full pb-5'>
            <div className="flex items-center space-x-2">
        <NoSymbolIcon className="w-3 h-3 md:w-6 md:h-6 text-black" />
        <h2 className="text-xl md:text-3xl font-normal text-black ">
            {t("ProhibitedItemsTitle")}
        </h2>
      </div>   
      <div className='w-full grid grid-cols-2 md:grid-cols-4 gap-4 my-20'>
          <div>
            <CustomBlockCard
              image={ImageBlockOne}
              title={t("blockedItems.allLiquids")}
            />
          </div>
          <div>
            <CustomBlockCard
              image={ImageBlockTwo}
              title={t("blockedItems.batteries")}
            />
          </div>
          <div>
            <CustomBlockCard
              image={ImageBlockedThree}
              title={t("blockedItems.fakeBrands")}
            />
          </div>
          <div>
            <CustomBlockCard
              image={ImageBlockedFour}
              title={t("blockedItems.medicalSupplies")}
            />
          </div>
        </div>      
        </div>
    )
}

export default BlockedItems;