import React,{useState ,useEffect} from 'react';
import { MapPinIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import BoxOne from '../../assets/images/boxOne.png';
import BoxTwo from '../../assets/images/boxTwo.png';
import { useTranslation } from 'react-i18next';

const CustomContactUsCard =({FlagImage,title,Email,phoneNumberOne ,phoneNumberTwo,phoneNumberThree,Location,visible })=>{
    const { t ,i18n} = useTranslation();
  
const [direction, setDirection] = useState(
        localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
      );
      
      useEffect(() => {
        const handleStorageChange = () => {
          setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
        };
      
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
      }, []);
      
      useEffect(() => {
        const language = localStorage.getItem("language");
        setDirection(language === "ar" ? "rtl" : "ltr");
      }, [localStorage.getItem("language")]); 
      
    return (
        <div className="flex items-center justify-center w-full" >
      <div className="flex items-center">
     
        {/* Title Section */}
        <div className="bg-white shadow-lg h-[300px] md:h-[400px] 
         px-4 py-8 flex flex-col justify-between " >
          <div className='flex flex-col items-center justify-center '>
            <img
              src={FlagImage}
              alt='flag'
              className='w-20 '  // Add bottom margin to space the image from the title
            />
            <div className='w-1/2 px-1 py-1 text-center bg-custom-yellow md:px-2 md:py-2'>
              <h2 className="text-lg text-black md:text-2xl ">{title}</h2>
            </div>
          </div>

          <p className="flex items-center text-sm text-gray-500">
            <EnvelopeIcon className="w-4 h-4 me-2 text-custom-yellow" />
              <a href="mailto:info@example.com" className="text-gray-500 hover:underline">{Email}</a>
          </p>
          <p className="flex items-center text-sm text-gray-500">
            <PhoneIcon className={`h-4 w-4 me-2 text-custom-yellow ${direction === "rtl"?"rotate-[270deg]" :""}`} />

              <a href="tel:+447418351039" className="text-gray-500 hover:underline">{phoneNumberOne}</a>
          </p>
          {visible && (
                  <div className="h-auto transition-all duration-300 opacity-100">
                    <p className="flex items-center space-x-2 text-sm text-gray-700">
                      <PhoneIcon className={`h-5 w-5 me-2 text-custom-yellow ${direction === "rtl"?"rotate-[270deg]" :""}`} />
                      <a
                        href={`tel:${phoneNumberTwo}`}
                        className="text-gray-700 hover:underline"
                      >
                        {phoneNumberTwo}
                      </a>
                    </p>
                  </div>
          )}
                
          <p className="flex items-center text-sm text-gray-500">
          <MapPinIcon className="w-4 h-4 me-2 text-custom-yellow" />

            <a
              href="https://www.google.com/maps/search/?api=1&query=72+Gameat+Al-dewal+Al-Arabia,+Mohandessien"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:underline"
            >
            {Location}
            </a>
          </p>
        </div>

      </div>
    </div>
    )
}

export default CustomContactUsCard;