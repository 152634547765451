import React from "react";
import Header from "../component/sharedComponents/header";
import AskSection from '../component/home/askSection';
import FooterUser from '../component/sharedComponents/footer';
import ImportantServices from '../component/home/importantServices';
import CustomIntroSection from "../component/explainBuyPage/customIntroSection";
import { useTranslation } from "react-i18next";
import zaraSale from "../assets/images/zaraSale.png"
const NewStores =()=>{
      const {t} = useTranslation();
    
    return (
        <div className="">
            <Header />
            <div className="mt-[55px] md:mt-[54px] lg:mt-[75px]">
                <CustomIntroSection
                    title={t("allShippingSection.serviceUpdates")}
                />
            </div>
            {/* <ImportantServices/> */}
            <div className="max-container">
                <div className="md:flex my-10 gap-4">
                    <div className="md:w-1/2 text-black text-right flex flex-col gap-5">
                        <h3 className=" text-2xl font-bold">تسوق الآن بأفضل الأسعار!</h3>
                        <p className="text-lg">تسوق بأفضل الأسعار مع Zara واستمتع بأحدث صيحات الموضة بخصومات حصرية!</p>
                        <p className="text-lg">🌟 عروض لا تُقاوم – لفترة محدودة فقط! 🌟</p>
                        <p className="text-lg">🔸 ✨ العرض الأول | خصومات حتى 10%</p>
                        <p className="text-lg"> احصل على خصم يصل إلى 10% على أحدث تشكيلات الملابس العصرية للرجال والنساء والأطفال. تألق بإطلالة أنيقة تناسب جميع الأوقات بأسعار مذهلة!</p>
                        <p className="text-lg">🔸 👕 العرض الثاني | اشترِ قطعتين واحصل على الثالثة مجانًا</p>
                        <p className="text-lg">تسوق من مجموعات Zara الحصرية، وكن جزءًا من الموضة العصرية مع هذا العرض الفريد!</p>
                        <p className="text-lg">🔸 👠 العرض الثالث | خصومات حتى 5% على الأحذية والإكسسوارات  أكمل إطلالتك بأجمل الأحذية والإكسسوارات التي تعكس شخصيتك بأسعار مخفضة!</p>
                        <p className="text-lg">📅 العروض متاحة الان</p>
                    </div>
                    <div className="md:w-1/2">
                        <img 
                            src={zaraSale} 
                            alt="Background" 
                            className="  w-full h-full "
                        />
                    </div>
                    
                </div>
            </div>
            <ImportantServices/>
            <AskSection/>
            <FooterUser/>
        </div>
    )
}
export default NewStores;