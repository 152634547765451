import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencies } from "../../redux/reducer/currancyReducer"; // Adjust the path as needed
import { useTranslation } from "react-i18next";

const CustomDropDownNavBarCurrancy = ({ language = "en" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { currencies, loading, error } = useSelector((state) => state.currencies);

  useEffect(() => {
    // Fetch currencies on component mount
    dispatch(getCurrencies());
  }, [dispatch]);

  const toggleDropdown = () => {
    // setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: "relative" }} className="block w-full px-4 py-2 text-sm font-medium text-center text-gray-700 rounded-lg md:text-sm hover:bg-gray-100">
      <button
        ref={buttonRef}
        className="text-sm"
        onClick={toggleDropdown}
        style={{
          background: "transparent",
          border: "none",
          color: "white",
        }}
      >
        <span className="text-center text-gray-700 md:font-bold">
          {t("Currencies") }
        </span>
      </button>
      {isOpen && (
        <ul
          ref={dropdownRef}
          className="z-50 absolute left-10 bg-white shadow-lg max-h-[200px] overflow-y-auto p-2 border border-gray-300 rounded-md"

        >
          {loading ? (
            <li style={{ padding: "10px", textAlign: "center" }}>Loading...</li>
          ) : error ? (
            <li style={{ padding: "10px", textAlign: "center", color: "red" }}>
              {error}
            </li>
          ) : (
            currencies.map((currency) => (
              <li
                key={currency.id}
              
                style={{
               
                  padding: "10px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "5px",
                }}
              >
                <a
                  href={currency.href}
                  className="text-black"
      
                >
                  {currency.name.ar}
                </a>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default CustomDropDownNavBarCurrancy;
