import { motion } from "framer-motion";
import { useRef } from "react";
import { useInView } from "framer-motion";

const FadeInWhenVisible = ({ children }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
            style={{ minHeight: "100px" }} // إضافة ارتفاع افتراضي
        >
            {children}
        </motion.div>
    );
};

export default FadeInWhenVisible;
