import React,{useState , useEffect} from "react";
import { ChevronDoubleDownIcon, InboxIcon } from '@heroicons/react/24/outline';
import CustomLines from "./customLines";
import CustomBigText from "../sharedComponents/customBigText";
import image from '../../assets/images/allShipping.png';
import CustomFlags from '../../component/sharedComponents/customFlags';
import imageTurkey from '../../assets/images/turkey.png';
import imageOroba from '../../assets/images/oroba.png';
import imageWorld from '../../assets/images/world.png';
import CustomButton from "../sharedComponents/customButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BoxSection6 from "../../assets/images/BoxSection6.png"

const AllShippingSection = () => {
    const {t} =useTranslation();
      const navigate = useNavigate();
      const [direction, setDirection] = useState(
            localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
          );
          
          useEffect(() => {
            const handleStorageChange = () => {
              setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
            };
          
            window.addEventListener("storage", handleStorageChange);
            return () => window.removeEventListener("storage", handleStorageChange);
          }, []);
          
          useEffect(() => {
            const language = localStorage.getItem("language");
            setDirection(language === "ar" ? "rtl" : "ltr");
          }, [localStorage.getItem("language")]); 
        
      const handleCountShipping = () => {
        navigate('/CountShippingPage', { replace: false });
        window.location.reload(); // Force reload after navigation
      };
    return (
        <div className='relative' dir={direction}>
            <img 
            src={BoxSection6} 
            alt="Background" 
            className="absolute left-0 transform -translate-y-1/2 top-1/2 w-14 md:w-20"
          />
            <div className="relative items-center justify-center md:flex max-container">
            <div className="flex flex-col items-center w-full text-center md:w-1/2">
                    <div className="flex flex-col items-center justify-center">
                        <CustomLines />
                        <CustomBigText title={t("allShippingSection.serviceUpdates")} className="mt-4" />
                    </div>
                    <div  className="mt-5">
                        <p className="text-sm font-medium text-gray-600">
                        {t("allShippingSection.serviceDescription1")}
                        </p>
                    </div>
                    <div className="flex items-center justify-center gap-8 mt-10">
                        <CustomFlags 
                            image={imageTurkey}
                            title={t("allShippingSection.turkish")}
                        />
                        <h2 className="mt-10 text-2xl font-semibold text-black">{t("allShippingSection.and")}</h2>
                        <CustomFlags 
                            image={imageOroba}
                            title={t("allShippingSection.european")}
                        />
                    </div>
                    <div className="mt-5">
                        <p className="text-gray-600 ">
                        {t("allShippingSection.serviceDescription2")}
                    </p>
                    </div>
                    <div className="flex flex-col items-center justify-center mt-5">
                        <ChevronDoubleDownIcon className="w-6 h-6 mb-2 text-black" />
                        <img src={imageWorld} alt="world" className="w-20 h-20" />
                        <div className="inline-block px-8 py-2 mt-2 bg-custom-yellow">
                            <h2 className="text-black ">{t("allShippingSection.worldwide")}</h2>
                        </div>
                        <ChevronDoubleDownIcon className="w-6 h-6 mt-3 mb-2 text-black" />
                        <CustomButton 
                            children={t("allShippingSection.learnMore")}
                            icon={InboxIcon} 
                            onClick={handleCountShipping}
                        />
                    </div>
                </div>
        <div className="hidden w-1/2 md:block" >
    <img
        src={image}
        alt="image"
        className="max-h-[600px]  mb-8 mx-auto"
    />
    </div>

                
                <div className="flex justify-center md:hidden">
                <img
                    src={image}
                    alt="image"
                    className="h-[600px] md:w-1/2"
                />
                </div>
            </div>
        </div>
    );
};

export default AllShippingSection;
