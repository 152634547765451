import * as React from 'react';
import { useEffect,useState } from "react";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {  useLocation } from 'react-router-dom';
import HomeUser from './pages/home';
import ExplainBuyPage from './pages/explainBuyPage';
import CountShippingPage from './pages/countShippingPage';
import ChooseCategoryTrukeyPage from './pages/chooseCatgoryTrukeyPage';
import ChooseCategoryEroupaPage from './pages/chooseCategoryEroupaPage';
import ChooseShoppingEroupaPage from './pages/chooseShoppingEroupaPage';
import ChooseShoppingTrukeyPage from './pages/chooseShoppingTrukeyPage';
import TermsAndConditions from './pages/termsAndConditions';
import CookiesPolicyPage from './pages/cookiesPolicyPage';
import PrivacyPolicyPage from './pages/privacyPolicyPage';
import QualityControlAndShipmentPhotography from './pages/qualityPage';
import ShippingInsurance from './pages/shippingInsurancePage';
import OurServicesPage from './pages/ourServicesPage';
import ContactUsPage from './pages/contactUsPage';
import LoginPage  from './pages/loginPage';
import RegistrationPage from './pages/registerationPage';
import ForgetPasswordPage from './pages/forgetPasswordPage';
import ResetPasswordPage from './pages/resestPasswordPage';
import AccountUser  from './pages/accountUser';
import IFramePage from './pages/iFramePage';
import "./i18n"; // Import i18n configuration
import OTPPage from './pages/otpPage';
import { AuthProvider } from './component/auth/AuthContext';
import { useTranslation } from "react-i18next";
import NewStores from './pages/NewStores';
import main from "./assets/images/main.png";
import Dashboard from './pages/Dashboard';

const ResetScroll = ({ children }) => {
  const location = useLocation();

  const { i18n } = useTranslation();
const [direction, setDirection] = useState(
        localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
      );
      
      useEffect(() => {
        const handleStorageChange = () => {
          setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
        };
      
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
      }, []);
      
      useEffect(() => {
        const language = localStorage.getItem("language");
        setDirection(language === "ar" ? "rtl" : "ltr");
      }, [localStorage.getItem("language")]); 
      
  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      i18n.changeLanguage(savedLanguage);
      // document.documentElement.dir = savedLanguage === "en" ? "rtl" : "ltr";
    }, [i18n]);

  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

const App = () => {
  return (
    <div className="relative min-h-screen bg-yellow-50" >
      <img 
        src={main} 
        alt="Background" 
        className="absolute top-0 left-0 object-cover w-full h-full opacity-75"
      />
    <div className="relative z-10">

      <AuthProvider >
        <ResetScroll>
          <Routes>
            <Route path="/" element={<HomeUser />} />
            <Route path="/ExplainBuyPage" element={<ExplainBuyPage/>} />
            <Route path="/CountShippingPage" element={<CountShippingPage/>} />
            <Route path ="/ChooseCategoryTrukeyPage" element={<ChooseCategoryTrukeyPage/>}/>
            <Route path='/ChooseCategoryEroupaPage' element={<ChooseCategoryEroupaPage/>}/>
            <Route path='/ChooseShoppingEroupaPage/:name' element={<ChooseShoppingEroupaPage/>}/>
            <Route path ='/ChooseShoppingTrukeyPage/:name' element ={<ChooseShoppingTrukeyPage/>}/>
            <Route path='/TermsAndConditions' element={<TermsAndConditions/>}/>
            <Route path ='/CookiesPolicyPage' element ={<CookiesPolicyPage/>}/>
            <Route path='/PrivacyPolicyPage' element ={<PrivacyPolicyPage/>}/>
            <Route path ='/QualityControlAndShipmentPhotography' element = {<QualityControlAndShipmentPhotography/>}/>
            <Route path='/ShippingInsurance' element={<ShippingInsurance/>}/>
            <Route path ='/OurServicesPage' element ={<OurServicesPage/>}/>
            <Route path='/ContactUsPage' element={<ContactUsPage/>}/>
            <Route path ='/LoginPage' element={<LoginPage/>}/>
            <Route path='/RegistrationPage' element={<RegistrationPage/>}/>
            <Route path='/ForgetPasswordPage' element={<ForgetPasswordPage/>}/>
            <Route path='/ResetPasswordPage' element={<ResetPasswordPage/>}/>
            <Route path ='/AccountUser' element ={<AccountUser />}/>
            <Route path ='/Dashboard' element ={<Dashboard />}/>
            <Route path ='/IFramePage' element={<IFramePage/>}/>
            <Route path ='/otpPage' element={<OTPPage />}/>
            <Route path ='/newStores' element={<NewStores />}/>
          </Routes>
        </ResetScroll>
      </AuthProvider>
    </div>
  </div>
  );
}

export default App;
