import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListOfBoxShipmentOrders from './listBoxShipmentOrders';
import ListOfBoxCardOrders from './listOfBoxOrdersCart';
import ShipmentItems from './shipmentItems';
export default function BoxChooseButton() {
  const [selectedMenuItem, setSelectedMenuItem] = useState('ListOfBoxCardOrders');
  const [showButtons, setShowButtons] = useState(true); // State to track button visibility
  const { t } = useTranslation();

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  // Handle the "startShimpment" button click to hide the buttons
  const handleStartShipmentClick = () => {
    setShowButtons(false); // Hide the buttons when the "startShimpment" button is clicked
    setSelectedMenuItem('ListOfBoxShipmentOrders'); // Optionally set the menu item to shipment orders
  };

  return (
    <div className="w-full flex flex-col items-center mt-10 md:mr-10">
      {/* Buttons Section */}
      {showButtons && (
        <div className="flex mb-6 space-x-4">
          <button
            className={`text-lg px-6 py-3 font-bold rounded-md focus:outline-none transition-colors ${
              selectedMenuItem === 'ListOfBoxCardOrders' ? 'bg-custom-yellow text-black' : 'bg-white text-black'
            }`}
            onClick={() => handleMenuItemClick('ListOfBoxCardOrders')}
          >
            {t("Repackaging")}
          </button>
          <button
            className={`text-lg px-6 py-3 font-bold rounded-md focus:outline-none transition-colors ${
              selectedMenuItem === 'ListOfBoxShipmentOrders' ? 'bg-custom-yellow text-black' : 'bg-white text-black'
            }`}
            onClick={handleStartShipmentClick} // Hide buttons when this is clicked
          >
            {t("startShimpment")}
          </button>
        </div>
      )}

      {/* Content Section */}
      <div className="w-full">
        {selectedMenuItem === 'ListOfBoxShipmentOrders' && (
          <div>
            {/* <ShipmentItems /> */}
          </div>
        )}
        {selectedMenuItem === 'ListOfBoxCardOrders' && (
          <div>
            {/* <ListOfBoxCardOrders /> */}
          </div>
        )}
      </div>
    </div>
  );
}
