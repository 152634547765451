import React, { useEffect ,useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkJWT } from "../../redux/reducer/jwtCheckReducer";
import CopyableListInput from "../cartAddress/CustomInputAndTitle";
import CustomCardCount from "./customCardCount";
import { useTranslation } from "react-i18next";
import { getAddressesByUserId } from "../../redux/reducer/userAddressReducer";

const BoxSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const { addresses, loading, error } = useSelector((state) => state.userAddress);

  useEffect(() => {
    dispatch(getAddressesByUserId());
  }, [dispatch]);

    // useEffect(() => {
    //   if (addresses) {
    //   }
    // }, [addresses]);
    const boxNumber = addresses?.user?.boxNumber|| t("Unknown");
    
  if (loading) {
    return <p>{t("loading")}...</p>;
  }

  if (error) {
    return <p className="text-red-500">{t("error_occurred")}: {error}</p>;
  }

  return (
    <div className="w-full">
      {/* Box Number Section */}
      <div className="items-center gap-4 mb-6 md:flex">
        <h3 className="text-xl text-black">{t("BoxNumber")}</h3>
        <CopyableListInput title={boxNumber} />
      </div>

      {/* Card Counts Section */}
      <div className="flex items-center justify-center w-full">
        <div className="w-2/3">
          <CustomCardCount
            title={t("MyBox")}
            count="0"
            subTitle={t("Piece")}
            className="flex-1"
          />
        </div>
        <div className="w-2/3">
          <CustomCardCount
            title={t("Shipments")}
            count="0"
            subTitle={t("Shipment")}
            className="flex-1"
          />
        </div>
        <div className="w-2/3">
          <CustomCardCount
            title={t("Notifications")}
            count="0"
            subTitle={t("NewNotification")}
            className="flex-1"
          />
        </div>
      </div>
    </div>
  );
};

export default BoxSection;
