import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

export default function CustomListBrands({ id }) {
  const scrollRef = useRef(null);
  const [brands, setBrands] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;

    axios
      .get(`https://markaship.com/api/area/${id}`)
      .then((response) => {
        if (response.data?.area?.brand) {
          setBrands(response.data.area.brand);
        }
      })
      .catch((error) => console.error("Error fetching brands:", error));
  }, [id]);

  useEffect(() => {
    if (!scrollRef.current) return; 
    scrollRef.current.scrollTo({ left: 0, behavior: "instant" });
  }, [brands]);

  const scrollOneItem = (direction) => {
    if (!scrollRef.current || brands.length === 0) return; 
    
    const itemWidth = scrollRef.current.children[0]?.offsetWidth || 0;
    if (itemWidth === 0) return; 
  
    const scrollAmount = direction === "left" ? -itemWidth : itemWidth;
    scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
  
    setTimeout(() => {
      if (!scrollRef.current) return; 
      if (scrollRef.current.scrollLeft + scrollRef.current.clientWidth >= scrollRef.current.scrollWidth) {
        scrollRef.current.scrollTo({ left: 0, behavior: "instant" });
      }
    }, 600);
  };
  

  useEffect(() => {
    if (brands.length === 0 || !scrollRef.current) return;
  
    const interval = setInterval(() => {
      scrollOneItem("right");
    }, 3000);
  
    return () => clearInterval(interval);
  }, [brands]);

  const handleBrandClick = (brandLink) => {
    if (brandLink) {
      navigate("/IFramePage", { state: { iframeSrc: brandLink } });
      setTimeout(() => {
        window.location.reload();
      }, 0);
    } else {
      console.error("Brand link is unavailable.");
      alert("Cannot navigate. The brand link is missing or invalid.");
    }
  };

  return (
    <div className="items-center justify-center pb-2" dir="ltr">
      <div className="flex flex-col items-center justify-center text-center">
        <div className="relative flex items-center max-w-[320px] lg:max-w-[350px] md:max-w-[220px] mx-auto">
          {/* زر التمرير لليسار */}
          {brands.length > 1 && (
            <button onClick={() => scrollOneItem("left")} className="absolute md:block hidden left-[-40px] z-10 p-2 rounded-full focus:outline-none">
              <ChevronDoubleLeftIcon className="w-6 h-6 text-black" />
            </button>
          )}

          <div ref={scrollRef} className="flex h-full space-x-6 overflow-x-auto scrollbar-hidden" style={{ scrollSnapType: "x mandatory", maxWidth: "900px" }}>
            {brands.map((brand) => (
              <div key={brand._id} className="flex-none w-1/4 md:w-[125px] rounded-lg p-2 md:p-4" style={{ scrollSnapAlign: "start" }}>
                <div onClick={() => handleBrandClick(brand.link)} style={{ cursor: "pointer" }} className="flex items-center h-full">
                  <img src={brand.img} alt={brand.name.en} className="w-full h-auto" />
                </div>
              </div>
            ))}
          </div>

          {/* زر التمرير لليمين */}
          {brands.length > 1 && (
            <button onClick={() => scrollOneItem("right")} className="absolute right-[-40px] md:block hidden z-10 p-2 rounded-full focus:outline-none">
              <ChevronDoubleRightIcon className="w-6 h-6 text-black" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
