import React, { useState } from 'react';
import CustomArrowDown from '../sharedComponents/customArrowDown';

const CustomHowItWorks = ({ image, title, visible }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave} 
            className="flex flex-col items-center justify-center w-[90%] md:w-[80%]  text-center cursor-pointer group"
        >
            {/* Card Image */}
            <img
                src={image}
                alt="image"
                className="w-full h-[180px]"
            />
            {/* Title Section */}
            <div
    className={`flex justify-center items-center px-4 w-full h-[150px] cursor-pointer duration-300 group-hover:bg-black bg-custom-yellow`}
>
    <h2 className={`sm:text-sm text-sm/7 lg:text-sm/7 text-center duration-300 group-hover:text-white text-black font-semibold `}>
        {title}
    </h2>
</div>

            {/* Arrow Section */}
            <div className="my-5 md:mt-5 md:my-0">
                {visible ? (
                    <CustomArrowDown />
                ) : (
                    // Placeholder for consistent spacing
                    <div className="h-[24px]" />
                )}
            </div>
        </div>
    );
};

export default CustomHowItWorks;
