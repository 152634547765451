import React ,{useState , useEffect} from 'react';
import BoxOne from '../../assets/images/boxOne.png';
import BoxTwo from '../../assets/images/boxTwo.png';

const CustomTitleWihBox =({title})=>{
    const [direction, setDirection] = useState(
            localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
          );
          
          useEffect(() => {
            const handleStorageChange = () => {
              setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
            };
          
            window.addEventListener("storage", handleStorageChange);
            return () => window.removeEventListener("storage", handleStorageChange);
          }, []);
          
          useEffect(() => {
            const language = localStorage.getItem("language");
            setDirection(language === "ar" ? "rtl" : "ltr");
          }, [localStorage.getItem("language")]); 
          
          
    return(
      <div className="">
      <div className="flex items-center justify-center">
        {/* BoxOne */}
        <img
          src={direction === "rtl" ? BoxOne : BoxTwo}
          alt="box"
          className={`w-20 h-20 relative top-[-10px] ${direction === "rtl" ? "right-[10px] md:right-[20px]" : "left-[10px] md:left-[20px]"}`}
        />
        {/* Title Section */}
        <div className="bg-custom-yellow text-center inline-block px-4 md:px-8 py-2 min-w-[175px]">
          <h2 className="text-lg sm:text-xl font-semibold lg:text-3xl  text-black">{title}</h2>
        </div>
        {/* BoxTwo */}
        <img
          src={direction === "ltr" ? BoxOne : BoxTwo}
          alt="box"
          className={`w-20 h-20 relative  top-[-10px] ${direction === "ltr" ? "right-[10px] md:right-[20px]" : "left-[10px] md:left-[20px]"}`}
        />
      </div>
    </div>
    )
}

export default CustomTitleWihBox;