import React, { useState , useEffect } from "react";
import { ChevronDoubleDownIcon, CalculatorIcon } from '@heroicons/react/24/outline';
import CalcuImage from '../../assets/images/mobileimage.png';
import CustomDropdown from '../sharedComponents/customDropDown';
import CustomCheckBox from '../sharedComponents/customCheckBox';
import CustomInput from "../sharedComponents/customInput";
import CustomButton from "../sharedComponents/customButton";
import CustomTitle from '../sharedComponents/customTitle';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch and useSelector
import { checkShipmentCost } from '../../redux/reducer/shipmentCostReducer'; // Import the action
import { useTranslation } from "react-i18next";
import BoxSection7 from "../../assets/images/BoxSection7.png"
import CustomDropdownCountry from "../sharedComponents/CustomDropdownCountry";


const CalcalateShippingSection = () => {
  const [inputValue, setInputValue] = useState(""); // State for weight
  const [selectedFrom, setSelectedFrom] = useState(null); // State for location from
  const [selectedTo, setSelectedTo] = useState(null); // State for location to
  const [hasDimensions, setHasDimensions] = useState(false); // State for dimensions checkbox
  const { t,i18n } = useTranslation();
  const dispatch = useDispatch(); 
  const { cost = null, loading = false, error = null } = useSelector((state) => state.shipment || {}); 
  const [formData, setFormData] = useState({
    quantity: "",
    weight: "",
    height: "",
    width: "",
    length: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Menu items for dropdowns (location options)
  const menuItemsLocationFrom = [
    { id: 1, name: t("Europa"), link: '#' },
    { id: 2, name: t("Turkey"), link: '#' },
  ];

  const menuItemsLocationTo = [
    { id: 1, name: t("countries.SA"), link: "#" },
    { id: 2, name: t("countries.AE"), link: "#" },
    { id: 3, name: t("countries.EG"), link: "#" },
    { id: 4, name: t("countries.QA"), link: "#" },
    { id: 5, name: t("countries.KW"), link: "#" },
    { id: 6, name: t("countries.OM"), link: "#" },
    { id: 7, name: t("countries.BH"), link: "#" },
    { id: 8, name: t("countries.JO"), link: "#" },
    { id: 9, name: t("countries.LB"), link: "#" },
    { id: 10, name: t("countries.SY"), link: "#" },
    { id: 11, name: t("countries.IQ"), link: "#" },
    { id: 12, name: t("countries.YE"), link: "#" },
    { id: 13, name: t("countries.MA"), link: "#" },
    { id: 14, name: t("countries.DZ"), link: "#" },
    { id: 15, name: t("countries.TN"), link: "#" },
    { id: 16, name: t("countries.LY"), link: "#" },
    { id: 17, name: t("countries.SD"), link: "#" },
    { id: 18, name: t("countries.PS"), link: "#" },
    { id: 19, name: t("countries.SO"), link: "#" },
    { id: 20, name: t("countries.DJ"), link: "#" },
    { id: 21, name: t("countries.MR"), link: "#" },
    { id: 22, name: t("countries.KM"), link: "#" }
  ];


  const [direction, setDirection] = useState(
    localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
  );
  
  useEffect(() => {
    const handleStorageChange = () => {
      setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
    };
  
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);
  
  useEffect(() => {
    const language = localStorage.getItem("language");
    setDirection(language === "ar" ? "rtl" : "ltr");
  }, [localStorage.getItem("language")]); 
  
useEffect(() => {
  const savedLanguage = localStorage.getItem("language");
  i18n.changeLanguage(savedLanguage);
  // document.documentElement.dir = savedLanguage === "en" ? "rtl" : "ltr";
}, [i18n]);

  const handleCheckPrice = () => {
    const shipmentData = {
      weight: inputValue,
      zone: selectedFrom,  // Pass the actual selected value from the dropdown
      country: selectedTo, // Pass the actual selected value from the dropdown
      hasDimensions: hasDimensions,
    };

    
    dispatch(checkShipmentCost(shipmentData)); // Dispatch the action
  };

  return (
    <div className='relative' dir={direction}>  
      <img 
        src={BoxSection7} 
        alt="Background" 
        className="absolute right-0 transform -translate-y-1/2 top-1/2 w-14 md:w-20"
      />
      <div className="relative pt-5 max-container">
        <CustomTitle title={t("calculateShippingSection.title")} />
        <div className="items-center justify-center md:flex">
          {/* Left Half - Image */}
          <div className="flex items-center justify-center w-full md:w-1/2">
            <img src={CalcuImage} alt="Shipping" className="object-cover w-full mb-4" />
          </div>

          {/* Right Half - Dropdowns and Checkbox */}
          <div className="flex-col w-full p-4 md:w-1/2">
            <CustomDropdown
              title={t("calculateShippingSection.shippingFrom")}
              menuItems={menuItemsLocationFrom}
              textColor="custom-gray"
              onSelect={(item) => setSelectedFrom(item.name)} // Update selected value
            />
            <CustomDropdownCountry
              title={t("calculateShippingSection.shippingTo")}
              menuItems={menuItemsLocationTo}
              textColor="custom-gray"
              onSelect={(item) => setSelectedTo(item.name)} // Update selected value
            />
            <CustomInput
              title={t("calculateShippingSection.enterWeight")}
              placeholder={t("calculateShippingSection.enterText")}
              textColor="gray-800"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)} // Handle weight input change
            />
            <div>
        <CustomCheckBox
          title={t("EnterDimensions")} // Translate or use i18n as needed
          onChange={() => setHasDimensions(!hasDimensions)} // Toggle checkbox
        />

  {hasDimensions && (
    <div className="mt-4 space-y-4" dir={"ar" ? "rtl" : "ltr"}>
      {/* Quantity and Weight */}
      <div className="flex gap-4">
        <div className="flex flex-col">
          <label className="mb-2 text-black" htmlFor="quantity">
          {t("quantity")}
          </label>
          <input
            type="number"
            id="quantity"
            name="quantity"
            value={formData.quantity}
            onChange={handleInputChange}
            className="text-black bg-white border border-gray-500 input"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 text-black" htmlFor="weight">
        {t("weight")}
          </label>
          <input
            type="number"
            id="weight"
            name="weight"
            value={formData.weight}
            onChange={handleInputChange}
            className="text-black bg-white border border-gray-500 input"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 text-black" htmlFor="height">
          {t("height")}
          </label>
          <input
            type="number"
            id="height"
            name="height"
            value={formData.height}
            onChange={handleInputChange}
            className="text-black bg-white border border-gray-500 input"
          />
        </div>
      </div>

      {/* Height, Width, and Length */}
      <div className="flex gap-4">
      
        <div className="flex flex-col">
          <label className="mb-2 text-black" htmlFor="width">
        {t("width")}
          </label>
          <input
            type="number"
            id="width"
            name="width"
            value={formData.width}
            onChange={handleInputChange}
            className="text-black bg-white border border-gray-500 input"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 text-black" htmlFor="length">
            {t("length")}
          </label>
          <input
            type="number"
            id="length"
            name="length"
            value={formData.length}
            onChange={handleInputChange}
            className="text-black bg-white border border-gray-500 input"
          />
        </div>
      </div>
    </div>
  )}

      </div>

            <div className="flex flex-col items-center justify-center h-full">
              <ChevronDoubleDownIcon className="w-6 h-6 my-2 text-black" />
              <CustomButton
                children={t("calculateShippingSection.checkPrice")}
                icon={CalculatorIcon}
                onClick={handleCheckPrice} // Call handleCheckPrice when button is clicked
              />
            </div>

            {/* Display loading, cost, and error */}
            {loading && <p>{t("calculateShippingSection.loading")}</p>}
            {error && <p className="text-red-500">{t("calculateShippingSection.error")}: {error}</p>}
            {cost && <p>{t("calculateShippingSection.shipmentCost")}: {cost}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalcalateShippingSection;
