import React from "react";
import Header from "../component/sharedComponents/header";
import FooterUser from "../component/sharedComponents/footer";
import IframePage from '../component/iFrame/iFrameSection';
import CopyLink from '../component/iFrame/copyLink';
const IFramePage =()=>{
    return(
        <div>
            <Header/>
                <div className="mt-[55px] md:mt-[54px] lg:mt-[75px]">
                    <CopyLink/>
                </div>
                <div className="">
                    <IframePage/>
                </div>
            <FooterUser/>
        </div>
    )
}

export default IFramePage;