import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PhoneIcon, WrenchIcon, TruckIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";

const NavBarMenuContactUsOurService = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(null);
  const [menuWidths, setMenuWidths] = useState({});
  const menuRef = useRef([]);
  const closeTimeout = useRef(null);

  const menuData = {
    // contactUs: {
    //   name: t("contact_us"),
    //   path: "/ContactUsPage",
    //   icon: <PhoneIcon className="hidden w-4 h-4 mr-5 md:block" />, // Hidden on mobile, visible on md and larger
    // },
    buyForMe: {
      name: t("buy_for_me"),
      path: "/ExplainBuyPage",
      icon: <ShoppingCartIcon className="hidden w-4 h-4 me-1 lg:block" />, // Hidden on mobile, visible on md and larger
    },
    countShipping: {
      name: t("count_shipping"),
      path: "/CountShippingPage",
      icon: <TruckIcon className="hidden w-4 h-4 me-1 lg:block" />, // Hidden on mobile, visible on md and larger
    },
    ourService: {
      name: t("our_services"),
      path: "/OurServicesPage",
      icon: <WrenchIcon className="hidden w-4 h-4 me-1 lg:block" />, // Hidden on mobile, visible on md and larger
    },
  };

  // useEffect(() => {
  //   const widths = {};
  //   menuRef.current.forEach((ref, index) => {
  //     if (ref) {
  //       widths[Object.keys(menuData)[index]] = ref.offsetWidth;
  //     }
  //   });
  //   setMenuWidths(widths);
  // }, [menuData]);

  const handleMouseEnter = (menuName) => {
    clearTimeout(closeTimeout.current);
    setOpenMenu(menuName);
  };

  const handleMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setOpenMenu(null);
    }, 200);
  };

  const handleClick = (e, path) => {
    e.preventDefault();
    navigate(path);
    window.location.href = path;
  };

  return (
    <div className="relative " style={{marginLeft:0 ,marginTop:0}}>
      <div className="justify-center gap-5 py-0 lg:py-3 flex lg:flex-row flex-col lg:mt-[5px] mt-5">
        {Object.keys(menuData).map((key, index) => (
          <div
            key={key}
            className="relative group"
            onMouseEnter={() => handleMouseEnter(menuData[key].name)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex flex-col ">
              <a
                href={menuData[key].path}
                ref={(el) => (menuRef.current[index] = el)}
                className={`lg:flex md:text-sm gap-1 text-white font-semibold rounded  items-stretch
                ${
                  openMenu === menuData[key].name
                    ? "group-hover:text-custom-yellow duration-300"
                    : "text-custom-yellow"
                }
                `}
                onClick={(e) => handleClick(e, menuData[key].path)}
              >
                {menuData[key].icon}
                {menuData[key].name}
              </a>
              <div
                style={{ width: `${menuWidths[menuData[key].name] || 50}px` }}
                className={`h-[4px] mx-auto mt-1 hidden lg:block group-hover:bg-custom-yellow duration-300`}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavBarMenuContactUsOurService;
