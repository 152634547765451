import React from "react";

const CustomShoppingCard = ({ image, title, subTitle }) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-4 text-center md:w-3/5">
      <img
        src={image}
        alt="image"
        className=""
      />
      <h2 className="text-lg font-semibold text-black ">
        {title}
      </h2>
      <p className="px-8 text-gray-800 ">
        {subTitle}
      </p>
    </div>
  );
};

export default CustomShoppingCard;
