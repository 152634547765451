import React, { useEffect } from "react";
import {
  UserIcon,
  ShoppingCartIcon,
  Bars4Icon,
  XMarkIcon,
  BellIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import ValidMennItem from "./validmenuItem";
import CustomUnloginMenu from "./customUnloginMenu";
import CustomDropDownNavBarCurrancy from './customDropDownCurrancy';
const CustomMenu = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  const iconSize = isMobile ? "lg:w-7 w-6 lg:h-7 h-6" : "lg:w-7 w-6 lg:h-7 h-6 ";

  return (
    <div className="flex justify-center gap-5 " style={{marginLeft:0}}>
      <Menu>
        <MenuButton className="flex items-center justify-center">
          <UserIcon className={`${iconSize} text-white duration-300 hover:text-custom-yellow`} />
        </MenuButton>
        <MenuItems className="absolute z-30 flex flex-col w-32 mt-10 bg-white rounded-lg shadow-lg lg:w-40">
          {/* <ValidMennItem/> */}
          <CustomDropDownNavBarCurrancy/>
          <CustomUnloginMenu />
        </MenuItems>
      </Menu>

    </div>
  );
};

export default CustomMenu;
