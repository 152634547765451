import React, { useState, useEffect } from "react";

export default function CustomInput({
  id,
  type = "text",
  placeholder,
  value,
  onChange,
  icon,
  isPassword = false,
  className = "",
}) {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [direction, setDirection] = useState(
    localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
  );
  
  useEffect(() => {
    const handleStorageChange = () => {
      setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
    };
  
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);
  
  // تحديث مباشر لما تتغير اللغة
  useEffect(() => {
    const language = localStorage.getItem("language");
    setDirection(language === "ar" ? "rtl" : "ltr");
  }, [localStorage.getItem("language")]); 
  

  return (
    <div className="relative w-full">
      {icon && (
        <span className="absolute inset-y-0 left-0 flex items-center text-gray-900">
          {icon}
        </span>
      )}

      <input
        id={id}
        dir={direction}
        type={isPassword && !isPasswordVisible ? "password" : type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`w-full px-3 md:px-5 py-3 text-black text-xs sm:text-lg border border-gray-300 rounded-lg bg-white 
         placeholder:text-sm sm:placeholder:text-base ${className}`}
      />

      {isPassword && (
        <span
          className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-900 cursor-pointer"
          onClick={() => setPasswordVisible(!isPasswordVisible)}
        >
          {isPasswordVisible ? "👁️" : "👁️‍🗨️"}
        </span>
      )}
    </div>
  );
}
