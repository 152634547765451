import React, { useEffect, useState, useRef } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import CustomBigText from '../sharedComponents/customBigText';
import StarReview from '../sharedComponents/customReviewStars';
import image from '../../assets/images/Polygon 6.png';
import image2 from '../../assets/images/Polygon 5.png';
import imageCircle from '../../assets/images/bd2c8624ccaf55dea33d5488c7f04b8b.png';
import { useTranslation } from 'react-i18next';
const Reviews = [
  {
    id: 1,
    name: { 
      ar: 'موقع رائع وسهل الاستخدام يحتوي على ميزات كثيرة تجعل التسوق ممتعًا وسلسًا دون أي تعقيد.', 
      en: 'A great and easy-to-use website with many features that make shopping smooth and enjoyable.' 
    },
    subTitle: { ar: 'سارة أحمد', en: 'Sara Ahmed' },
    image: 'https://randomuser.me/api/portraits/women/1.jpg'
  },
  {
    id: 2,
    name: { 
      ar: 'خدمة العملاء ممتازة والدعم سريع الاستجابة يساعد بحرفية في حل أي مشكلة خلال وقت قصير.', 
      en: 'Excellent customer service, responsive support team that helps professionally to solve any issue.' 
    },
    subTitle: { ar: 'أحمد فؤاد', en: 'Ahmed Fouad' },
    image: 'https://randomuser.me/api/portraits/men/1.jpg'
  },
  {
    id: 3,
    name: { 
      ar: 'الشحن سريع جدًا والمنتجات تصل بحالة ممتازة مع تغليف يضمن الحفاظ على الجودة بدون مشاكل.', 
      en: 'The shipping is very fast, and products arrive in excellent condition with secure and neat packaging.' 
    },
    subTitle: { ar: 'منى مصطفى', en: 'Mona Mostafa' },
    image: 'https://randomuser.me/api/portraits/women/2.jpg'
  },
  {
    id: 4,
    name: { 
      ar: 'المنتجات عالية الجودة تمامًا كما في الوصف، والتفاصيل واضحة مما يجعل الشراء آمنًا وسهلًا جدًا.', 
      en: 'High-quality products exactly as described, with clear details making the shopping experience safe.' 
    },
    subTitle: { ar: 'محمد كريم', en: 'Mohamed Karim' },
    image: 'https://randomuser.me/api/portraits/men/2.jpg'
  },
  {
    id: 5,
    name: { 
      ar: 'أفضل تجربة تسوق، الموقع بسيط، البحث سريع، وخيارات الدفع متعددة وآمنة وتناسب جميع المستخدمين.', 
      en: 'The best shopping experience, simple website, fast search, and multiple secure payment options.' 
    },
    subTitle: { ar: 'ريم عادل', en: 'Reem Adel' },
    image: 'https://randomuser.me/api/portraits/women/3.jpg'
  },
  {
    id: 6,
    name: { 
      ar: 'أنصح الجميع بهم، الأسعار رائعة، والخدمة ممتازة، والدعم الفني متعاون ويقدم حلولًا سريعة لأي استفسار.', 
      en: 'Highly recommend, great prices, excellent service, and helpful support team providing quick solutions.' 
    },
    subTitle: { ar: 'خالد يوسف', en: 'Khaled Youssef' },
    image: 'https://randomuser.me/api/portraits/men/3.jpg'
  },
];





export default function CustomReviewsSection() {
  const scrollRef = useRef(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const itemsPerScroll = 3; // Number of items per scroll action
  const numberOfDots = Math.ceil(Reviews.length / itemsPerScroll);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 640 && window.innerWidth < 1024);
  const {t} = useTranslation();
  

  const language = localStorage.getItem("language") || "ar";


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
      setIsTablet(window.innerWidth >= 640 && window.innerWidth < 1024);
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const handleClick = () => {
    // navigate('/eventInside');
  };

  const scrollOneItem = (direction) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollToIndex = (index) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      scrollRef.current.scrollTo({ left: index * itemWidth * itemsPerScroll, behavior: 'smooth' });
      setScrollIndex(index);
    }
  };

  const updateScrollIndex = () => {
    if (scrollRef.current) {
      const scrollLeft = scrollRef.current.scrollLeft;
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const newIndex = Math.floor(scrollLeft / (itemWidth * itemsPerScroll));
      setScrollIndex(newIndex);
    }
  };

  useEffect(() => {
    const handleScroll = () => updateScrollIndex();
    const container = scrollRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);
const [direction, setDirection] = useState(
      localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
    );
    
    useEffect(() => {
      const handleStorageChange = () => {
        setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
      };
    
      window.addEventListener("storage", handleStorageChange);
      return () => window.removeEventListener("storage", handleStorageChange);
    }, []);
    
    useEffect(() => {
      const language = localStorage.getItem("language");
      setDirection(language === "ar" ? "rtl" : "ltr");
    }, [localStorage.getItem("language")]); 
  
    
  return (
    <div className='bg-black'>
      <div className="items-center justify-center w-full pb-2">
        <div className="flex flex-col items-center justify-center text-center px-custom-padding">
          <div className="flex items-center justify-between" dir="ltr">
            {/* Line 1 */}
            <div className="bg-yellow-50 w-[4px] h-[60px] md:h-[100px] mr-24 lg:mr-40"></div>
            {/* Space Between */}
            <div className="bg-yellow-50 w-[4px] h-[60px] md:h-[100px]"></div>
          </div>
          <CustomBigText title={t("customerFeedback")} />
          <div className="items-center justify-center px-1 mx-2 mb-20 lg:px-8">
            <div className="relative flex items-center">
              {/* Left Arrow */}
              <button
                onClick={() => scrollOneItem('left')}
                className="absolute hidden lg:block lg:left-[-50px]
                mr-4 lg:mr-12 z-10 p-2 text-white bg-primary-color rounded-full shadow-md focus:outline-none"
              >
                <ChevronDoubleLeftIcon className='w-5 h-5 lg:w-10 lg:h-10' />
              </button>

              <div
              ref={scrollRef}
              className="flex w-full gap-6 overflow-x-auto scrollbar-hidden"
              style={{
                maxWidth: isMobile
                  ? "calc(1 * 290px + 1 * 30px)" // Full width for mobile (1 item)
                  : isTablet
                  ? "calc(2 * 290px + 1 * 30px)" // Width for 2 items on tablet
                  : "calc(3 * 290px + 2 * 30px)", // Width for 3 items on desktop
                scrollSnapType: "x mandatory",
              }}
            >
                {Reviews.map((product) => (
              <div className='bg-transparent '>
      <div
                  key={product.id}
                  className="flex-none w-[290px] sm:w-[250px] lg:w-[290px]
                  bg-white rounded-lg shadow-lg p-4 my-5"
                  style={{ scrollSnapAlign: "start" }}
                >
                    <div className="flex flex-col items-center justify-center mt-5">
                      <h2 className="items-center justify-center w-8 h-8 text-2xl text-center text-black rounded-full bg-custom-yellow">,,</h2>
                      <h2
                        className="mt-2 font-normal text-gray-700 cursor-pointer lg:text-lg xl:text-lg 1xl:text-xl 11xl:text-xl 2xl:text-xl"
                        onClick={handleClick}
                      >
                        {product.name[language]}
                      </h2>
                      <h2
                        className="mt-2 mb-3 font-semibold text-gray-700 cursor-pointer lg:text-lg xl:text-lg 1xl:text-xl 11xl:text-xl 2xl:text-xl"
                        onClick={handleClick}
                      >
                        {product.subTitle[language]}
                      </h2>
                      <StarReview />
                    </div>
                  </div>

                  <div className='flex items-center justify-center'>
                  <img
                    src={product.image}
                    alt='image'
                    className='relative bottom-[-10px] rounded-full w-10 h-10'
                  />
                  <img
                    src={direction === "rtl" ? image:image2}
                    alt='image'
                    className='relative top-[-32px]'
                  />
                </div>


              </div>
                ))}
              </div>

              {/* Right Arrow */}
              <button
                onClick={() => scrollOneItem('right')}
                className="absolute hidden lg:block lg:right-[-50px] ml-4 lg:ml-12 
                z-10 p-2 text-white bg-primary-color rounded-full shadow-md focus:outline-none"
              >
                <ChevronDoubleRightIcon className='w-5 h-5 lg:w-10 lg:h-10' />
              </button>
            </div>

            {/* Dots Container */}
            <div className="flex justify-center mt-4">
              {Array.from({ length: numberOfDots }).map((_, index) => (
                <div
                  key={index}
                  onClick={() => scrollToIndex(index)}
                  className={`w-3 h-3 mx-1 rounded-full cursor-pointer ${index === scrollIndex ? 'bg-primary-color' : 'bg-gray-300'}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
