import React from "react";

const CustomBigText = ({ title }) => {
  return (
    <div className="inline-block px-4 py-2 bg-custom-yellow md:px-8 min-w-[300px]">
      <h2 className="text-lg font-semibold text-center text-black md:text-xl lg:text-3xl">
        {title}
      </h2>
    </div>
  );
};

export default CustomBigText;
