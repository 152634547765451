import React, { useState ,useEffect} from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"; // Optional: Use heroicons or other icons

export default function CustomPasswordInput({
  id,
  label,
  placeholder,
  value,
  onChange,
}) {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };
const [direction, setDirection] = useState(
    localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
  );
  
  useEffect(() => {
    const handleStorageChange = () => {
      setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
    };
  
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);
  
  // تحديث مباشر لما تتغير اللغة
  useEffect(() => {
    const language = localStorage.getItem("language");
    setDirection(language === "ar" ? "rtl" : "ltr");
  }, [localStorage.getItem("language")]); 
  

  return (
    <div className="relative w-full">
      {/* Arabic Label */}
      {label && (
        <label
          htmlFor={id}
          className="block mb-2 text-sm font-semibold text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="relative" dir={direction === "ltr" ?"rtl" : "ltr"}>
        {/* Password Input Field */}
        <input
          id={id}
          dir={direction}
          type={isPasswordVisible ? "text" : "password"} // Toggle between text and password
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="w-full px-3 py-3 text-xs text-black bg-white border border-gray-300 rounded-lg md:px-5 sm:text-lg placeholder:text-sm sm:placeholder:text-base "
        />
        {/* Toggle Button */}
        <span
          className="absolute inset-y-0 flex items-center px-3 text-gray-700 cursor-pointer"
          onClick={togglePasswordVisibility} 
        >
          {isPasswordVisible ? (
            <EyeSlashIcon className="w-5 h-5" /> // Icon for hidden password
          ) : (
            <EyeIcon className="w-5 h-5" /> // Icon for visible password
          )}
        </span>
      </div>
    </div>
  );
}
