import React from 'react';
import { ClipboardDocumentIcon,BackspaceIcon } from '@heroicons/react/24/outline'; 
import CustomTitleTerms from '../termsAndConditions/customTitleTerms';
import CustomTermPoints from '../termsAndConditions/customTermPoints';
import CustomTextingCard from '../termsAndConditions/customCard';
import { useTranslation } from 'react-i18next';

const ContainerPrivacyAndPolicy = () => {
  const { t, i18n } = useTranslation();

  // Check if the current language is Arabic
  const isRTL = i18n.language === 'ar';

  return (
    <div
      className={`py-3  md:py-7 px-3 lg:px-40  ${isRTL ? 'rtl' : 'ltr'}`} // Set direction
      dir={isRTL ? 'rtl' : 'ltr'} // Add dir attribute for accessibility
    >
      <div
        className={` ${isRTL ? 'text-right' : 'text-left'} px-5 lg:px-20 shadow-lg py-10 lg:py-20 space-y-2 flex flex-col gap-y-5`}
      >
        {/* Privacy Policy */}
        <CustomTitleTerms title={t('privacyPolicyTitle')} />
        <CustomTermPoints title={t('personalInformationPrivacy')} />
        <CustomTermPoints title={t('privacyPolicyScope')} />
        
        {/* Consent for Personal Information Transfer */}
        <CustomTextingCard 
          title={t('consentForPersonalInformationTransfer')}
          Icon={ClipboardDocumentIcon}
          subTitle={t('consentText')}
        />
        
        <CustomTitleTerms title={t('consentForPersonalInformationTransfer')} />
        <CustomTermPoints title={t('consentToPrivacyPolicy')} />
        
        {/* Data Collection and Usage */}
        <CustomTitleTerms title={t('collectedDataUsage')} />
        <CustomTermPoints title={t('collectedDataDetails')} />
        <CustomTermPoints title={t('paymentCardData')} />
        <CustomTermPoints title={t('encryptedPassword')} />
        <CustomTermPoints title={t('usageData')} />
        <CustomTermPoints title={t('cookieData')} />
        
        {/* Data Usage and Disclosure */}
        <CustomTitleTerms title={t('dataDisclosureUsage')} />
        <CustomTermPoints title={t('taxAuthoritiesDisclosure')} />
        <CustomTermPoints title={t('thirdPartyDisclosure')} />
        <CustomTermPoints title={t('lawfulDisclosure')} />
        <CustomTermPoints title={t('safetyDisclosure')} />
        
        {/* Data Removal Requests */}
        <CustomTextingCard
          title={t('consentForPersonalInformationTransfer')}
          Icon={BackspaceIcon}
          subTitle={t('dataRemovalRequest')}
        />
        
        <CustomTitleTerms title={t('dataRemovalRequest')} />
        <CustomTermPoints title={t('dataRemovalInstructions')} />
        
        {/* Privacy Policy Amendments */}
        <CustomTitleTerms title={t('privacyPolicyAmendments')} />
        <CustomTermPoints title={t('privacyPolicyReview')} />
        <CustomTermPoints title={t('amendmentDate')} />
      </div>
    </div>
  );
};

export default ContainerPrivacyAndPolicy;
