import React from 'react';
import Header from '../component/sharedComponents/header';
import LoginForm from '../component/auth/loginForm';
import FooterUser from '../component/sharedComponents/footer';

const LoginPage =()=>{
    return(
        <div className=''>
            <Header/>
            <div className='mt-[55px] md:mt-[56px] lg:mt-[75px]  px-3 py-3 md:py-7 md:px-0  md:h-[60vh] lg:h-[70vh] flex flex-col justify-center'>
                <LoginForm/>
            </div>
            <FooterUser/>
        </div>
    )
}

export default LoginPage;