import React ,{useState ,useEffect} from 'react';
import CustomTitle from '../sharedComponents/customTitle';
import CustomHowItWorks from '../explainBuyPage/howItWorksCard';
import CustomArrowLeft from '../sharedComponents/customArrowLeft';
import ImageOne from '../../assets/images/countOne.png';
import ImageTwo from '../../assets/images/countTwo.png';
import ImageThree from '../../assets/images/countThree.png';
import ImageFour from '../../assets/images/countFour.png';
import ImageFive from '../../assets/images/countFive.png';
import CustomArrowRight from '../sharedComponents/customArrowRight';
import { useTranslation } from 'react-i18next';
const CountOrdersSection =()=>{
    const [isVisible, setIsVisible] = useState(true);
   const {t,i18n} =useTranslation();
   const [direction, setDirection] = useState(
               localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
             );
             
             useEffect(() => {
               const handleStorageChange = () => {
                 setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
               };
             
               window.addEventListener("storage", handleStorageChange);
               return () => window.removeEventListener("storage", handleStorageChange);
             }, []);
             
             useEffect(() => {
               const language = localStorage.getItem("language");
               setDirection(language === "ar" ? "rtl" : "ltr");
             }, [localStorage.getItem("language")]); 
             
         useEffect(() => {
             const savedLanguage = localStorage.getItem("language");
             i18n.changeLanguage(savedLanguage);
             // document.documentElement.dir = savedLanguage === "en" ? "rtl" : "ltr";
           }, [i18n]);
       
    const toggleVisibility = () => {
        setIsVisible((prev) => !prev);
    };
    return (
      <div className='' dir={direction}>
        <div className='max-container'>
          <CustomTitle title={t('countOrdersSection.title')} />

          <div className="items-center justify-center w-full mt-10 space-y-10 md:space-y-0 md:flex md:space-x-20">
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageThree}
                title={t('countOrdersSection.step3')}
                visible={isVisible}
              />
            </div>
            {/* end mobile */}
            <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageThree}
                title={t('countOrdersSection.step3')}
                // visible={isVisible}

              />
            </div>
             
            <div className="items-center justify-center hidden md:flex px-auto sm:block ">
              {direction === "rtl" ? <CustomArrowLeft /> : <CustomArrowRight />}
              
            </div>
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageTwo}
                title={t('countOrdersSection.step2')}
                visible={isVisible}
                />
            </div>
            {/* end mobile */}
           <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageTwo}
                title={t('countOrdersSection.step2')}
                />
            </div> 

            <div className="items-center justify-center hidden md:flex sm:block">
              {direction === "rtl" ? <CustomArrowLeft /> : <CustomArrowRight />}
            </div>
            <div className="flex justify-center md:w-1/3">
              <CustomHowItWorks
                image={ImageOne}
                title={t('countOrdersSection.step1')}
                visible={isVisible}/>
            </div>

          </div>

          <div className="items-center justify-end w-full space-y-10 md:mt-10 md:space-y-0 md:flex md:space-x-20">
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageFour}
                title={t('countOrdersSection.step4')}
                visible={isVisible}
              />
            </div>
            {/* end mobile */}
            
            <div className="justify-center hidden md:flex md:w-1/3">
              {/* <CustomHowItWorks
                image={ImageSix}
                visible={isVisible}
                title={t('countOrdersSection.step6')}
              /> */}
            </div>
            {/* <div className="items-center justify-center hidden md:flex px-auto sm:block">
              {direction === "ltr" ? <CustomArrowLeft /> : <CustomArrowRight />}
            </div> */}
            {/* mobile */}
            <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageFive}
                title={t('countOrdersSection.step5')}
                // visible={isVisible}

              />
            </div>
            {/* end mobile */}
            <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageFive}
                title={t('countOrdersSection.step5')}
              />
            </div>
            <div className="items-center justify-center hidden md:flex px-auto sm:block">
              {direction === "ltr" ? <CustomArrowLeft /> : <CustomArrowRight />}
            </div>
            
            <div className="justify-center hidden md:flex md:w-1/3">
              <CustomHowItWorks
                image={ImageFour}
                title={t('countOrdersSection.step4')}
              />
            </div>
            {/* mobile */}
            {/* <div className="flex justify-center md:hidden md:w-1/3">
              <CustomHowItWorks
                image={ImageSix}
                visible={isVisible}
                title={t('countOrdersSection.step6')}
              />
            </div> */}
            {/* end mobile */}
          </div>
        </div>
      </div>
    )
}
export default CountOrdersSection;