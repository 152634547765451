import React from 'react';

const CustomBlackButton =({children, onClick, icon: Icon})=>{
    return (
        <div>
    <button
        onClick={onClick}
        className="inline-flex items-center justify-center px-4 py-3 text-base font-bold text-white transition duration-300 ease-in-out transform bg-black rounded-custom md:text-sm hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color whitespace-nowrap"
      >
        {Icon && (
          <Icon className="w-4 text-white me-2 md:w-4" />
        )}
        {children}
      </button>
        </div>
    )
}

export default CustomBlackButton;