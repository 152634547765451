import React from 'react';
// import {LockClosedIcon} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
const TitleAndIcon =()=>{
  const {t} = useTranslation()
    return( 
<div>
  <div className="flex items-center space-x-2">
    {/* <LockClosedIcon className="w-4 h-4 md:w-6 md:h-6 text-black" /> */}
    <h2 className="text-xl md:text-3xl font-normal text-black mb-4">
     {t("ControlBoxSection")}
    </h2>
  </div>
  <p className="text-xs md:text-lg text-black mb-2">
    {t("WelcomeMessage")}
  </p>
</div>

      
    )
}

export default TitleAndIcon;