import React, { useState,useEffect } from 'react';

const CustomButton = ({ children, onClick, icon: Icon }) => {
    const [direction, setDirection] = useState(localStorage.getItem("language") === "ar" ? "ltr" : "rtl");
  
  useEffect(() => {
      const handleStorageChange = () => {
        setDirection(localStorage.getItem("language") === "en" ? "rtl" : "ltr");
      };
  
      window.addEventListener("storage", handleStorageChange);
      return () => window.removeEventListener("storage", handleStorageChange);
    }, []);
    
  return (
      <button 
        onClick={onClick} 
        dir={direction ==="rtl"?"ltr":"rtl"}
        className="z-0 inline-flex items-center justify-center px-4 py-2 text-base font-medium text-black transition duration-300 ease-in-out transform bg-custom-yellow rounded-custom md:text-sm hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color whitespace-nowrap "
      >
            {Icon && (
        <Icon className="w-4 text-black me-2" />
              
        )}
        {children}
      </button>
  );
};

export default CustomButton;

