import React from 'react';
import Header from '../component/sharedComponents/header';
import FooterUser from '../component/sharedComponents/footer';
import ResetPasswordForm from '../component/auth/resetPasswordForm';
const ResetPasswordPage =()=>{
    return(
        <div>
        <Header/>
        <div className='mt-[55px] md:mt-[56px] lg:mt-[75px]  px-3 py-3 md:py-7 md:px-0  md:h-[60vh] lg:h-[70vh] flex flex-col justify-center'>
            <ResetPasswordForm/>
        </div>
        <FooterUser/>
        </div>
    )
}

export default ResetPasswordPage;