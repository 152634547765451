import React, { useState,useEffect } from 'react';
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon ,BriefcaseIcon,ArrowUturnDownIcon } from '@heroicons/react/24/outline';
import image1 from '../../assets/images/introsection.png'; // Assuming two images for the slider
import image2 from '../../assets/images/silde.png';
import ImageYellowBoxLeft from '../../assets/images/boxOne.png';
import ImageYellowBoxRight from '../../assets/images/boxTwo.png';
import CustomButton from '../sharedComponents/customButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const ScrollerSection = ({ scrollToImportantServices }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  // State for tracking the current slide
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const images = [image1, image2]; // Array of images for the slider
  const texts = [
    { heading: t('scrollerSection.favoriteStore'), subheading: t('scrollerSection.toYourDoorstep') ,description:t('scrollerSection1.shopGlobally')},
    { heading: t("enjoyableShoppingJourney"), subheading: t( "startWithOneStepNow") ,description:t('scrollerSection.shopGlobally')}
  ]; // Array of text content for each slide
  
  // Handle left arrow click
  const goToPreviousSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  // Handle right arrow click
  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  // Handle dot click
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };
  // const handleServices = () => {
  //   navigate('/OurServicesPage', { replace: false });
  //   window.location.reload(); // Force reload after navigation
  // };
  
  const handleButtonTrukey = () => {
      navigate('/ChooseCategoryTrukeyPage', { replace: false });
    window.location.reload(); // Force reload after navigation
  };
  
  const handleButtonEroupa = () => {
    navigate('/ChooseCategoryEroupaPage', { replace: false });
  window.location.reload(); // Force reload after navigation
};

  // const handleButtonClick = (slideIndex) => {
  //   if (slideIndex === 0) {
  //     navigate('/ChooseCategoryTrukeyPage', { replace: false });
  //   } else {
  //     navigate('/ChooseCategoryEroupaPage', { replace: false });
  //   }
  //   window.location.reload(); // Force reload after navigation
  // };
  
  const [direction, setDirection] = useState(
          localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
        );
        
        useEffect(() => {
          const handleStorageChange = () => {
            setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
          };
        
          window.addEventListener("storage", handleStorageChange);
          return () => window.removeEventListener("storage", handleStorageChange);
        }, []);
        
        useEffect(() => {
          const language = localStorage.getItem("language");
          setDirection(language === "ar" ? "rtl" : "ltr");
        }, [localStorage.getItem("language")]); 

        
  return (
        <div className='bg-black'>          
          <div className='relative max-w-[1380px] mx-auto ' >
            <div className='max-container'>
              <div className="justify-between w-full py-5 mt-10 bg-black md:flex ">
                <button
                  onClick={goToNextSlide}
                  className="absolute right-0 py-2 text-white duration-300 transform -translate-y-1/2 bg-transparent rounded-full top-1/2 hover:text-custom-yellow"
                >
                  
                <ChevronDoubleRightIcon className="w-8 h-8 lg:w-10 lg:h-10 " />
                </button>
                <img src={images[currentSlide]} alt="slider" className="w-full h-auto md:w-1/2" />
                      
                    <div className="mt-[-75px] md:mt-0 flex flex-col items-end justify-center mx-5 md:mx-0">
                      <div className="inline-block px-8 py-2 bg-custom-yellow md:px-8">
                        <h2 className="items-end text-xl font-semibold text-black lg:text-3xl">{texts[currentSlide].heading}</h2>
                      </div>
                      <div className="flex items-center">
                        <div className="bg-white inline-block px-6 md:px-8 py-2 relative top-[-18px] md:top-[-18px] lg:top-[-14px]">
                          <h2 className="items-end text-lg font-semibold text-black lg:text-3xl" dir={direction}>{texts[currentSlide].subheading}</h2>
                        </div>
                        <img
                          src={direction==="rtl"?ImageYellowBoxLeft :ImageYellowBoxRight }
                          alt="box"
                          className={`w-20 h-20 relative top-[-20px] ${direction==="rtl"? "right-[16px]" :"left-[16px]" }`}
                        />
                      </div>
                      <div className={`${direction  === "rtl" ? "pr-7 md:pr-14" :"pl-7 md:pl-14"}`}>
                        <h2 className="mb-10 text-base text-white "dir={direction}>{texts[currentSlide].description}</h2>
                        
                        <div className="flex flex-col lg:flex-row justify-end gap-2 lg  :w-full w-[75%] ms-auto">
                          {currentSlide === 1 ? <button 
                          className="inline-flex items-center justify-center px-4 py-2 text-base text-white transition duration-300 ease-in-out transform bg-black border border-white rounded-custom sm:text-base md:text-base hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color whitespace-nowrap "
                          onClick={scrollToImportantServices}
                      >
                          {t('scrollerSection.contactUs')}
                          <ArrowUturnDownIcon className="w-4 text-white ms-2" />
                          </button>: 
                          
                          <button 
                          onClick={() => handleButtonTrukey()} 
                          dir={direction}
                          className="inline-flex items-center justify-center px-4 py-2 text-base text-white transition duration-300 ease-in-out transform bg-black border border-white rounded-custom sm:text-base md:text-base hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color whitespace-nowrap "
                        >
                          <BriefcaseIcon className="w-4 text-white me-2" /> 
                          {t('scrollerSection.Turkish') }
                        </button>}

                        {currentSlide === 0 ?<button 
                          onClick={() => handleButtonEroupa()} 
                          dir={direction}
                          className="z-0 inline-flex items-center justify-center px-4 py-2 text-base text-black transition duration-300 ease-in-out transform bg-custom-yellow rounded-custom sm:text-base md:text-base hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color whitespace-nowrap "
                        >
                          <BriefcaseIcon className="w-4 text-black me-2" /> 
                          { t('scrollerSection.European')}
                        </button>:""}
                        </div>
                      </div>

                  
                    </div>
                        {/* Arrow Navigation */}
                      <button
                        onClick={goToPreviousSlide}
                        className="absolute left-0 py-2 text-white duration-300 transform -translate-y-1/2 bg-transparent rounded-full top-1/2 hover:text-custom-yellow"
                      >
                        <ChevronDoubleRightIcon className={`w-8 h-8 transform rotate-180 lg:w-10 lg:h-10`} />
                      </button>
                </div>
                  {/* Dot Navigation */}
              <div className="flex justify-center gap-3 py-4 ">
                {images.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => goToSlide(index)}
                    className={`w-2.5 h-2.5 rounded-full ${currentSlide === index ? 'bg-custom-yellow' : 'bg-white'}`}
                  />
                ))}
                </div>
              </div>
            </div>
        </div>  
  );
};

export default ScrollerSection;
