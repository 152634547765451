import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { registerUser } from "../../redux/reducer/authReducer"; // Adjust the path as needed
import CustomTitleWihBox from "../sharedComponents/customTitleWithBox";
import CustomInput from "./CustomInput";
import CustomPasswordInput from "../accountUser/customPasswordInput";
import { EnvelopeIcon, LockOpenIcon, UserIcon, PhoneIcon, GlobeAltIcon, HashtagIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomDropdown from "../sharedComponents/customDropDown";
import PhoneCodeDropdown from "../sharedComponents/PhoneCodeDropdown";
import CountryDropdown from "../sharedComponents/CountryDropdown";
export default function RegistrationForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [boxNumber, setBoxNumber] = useState(() => Math.floor(100000 + Math.random() * 900000)); // Generate random 6-digit number
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [selectedCountryIso, setSelectedCountryIso] = useState("");
  
   const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    // let phone = phoneCode +phoneNum
    // let country=toString(country)
    const userData = { 
      name, 
      email, 
      phone, 
      countryCode, 
      password, 
      // boxNumber, 
      country, 
      // selectedCountryIso 
    };
  
    
    dispatch(registerUser(userData))
      .unwrap()
      .then(() => {
        alert("Registration successful!");
        handleClick(); // Trigger page reload and redirect after registration
      })
      .catch((error) => {
        console.error(error);
        alert(error?.message || "Registration failed.");
      });
  };

  // handleClick to force the reload and navigate
  const handleClick = () => {
    window.location.href = "/otpPage"; // Redirect to the login page
  };
  
  const [direction, setDirection] = useState(
      localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
    );
    
    useEffect(() => {
      const handleStorageChange = () => {
        setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
      };
    
      window.addEventListener("storage", handleStorageChange);
      return () => window.removeEventListener("storage", handleStorageChange);
    }, []);
    
    useEffect(() => {
      const language = localStorage.getItem("language");
      setDirection(language === "ar" ? "rtl" : "ltr");
    }, [localStorage.getItem("language")]); 


    // const menuListLocationTo = { name: t("calculateShippingSection.selectArea") };
    // const menuItemsLocationTo = [
    //   { id: 1, name: t("countries.SA"), link: "#" },
    //   { id: 2, name: t("countries.AE"), link: "#" },
    //   { id: 3, name: t("countries.EG"), link: "#" },
    //   { id: 4, name: t("countries.QA"), link: "#" },
    //   { id: 5, name: t("countries.KW"), link: "#" },
    //   { id: 6, name: t("countries.OM"), link: "#" },
    //   { id: 7, name: t("countries.BH"), link: "#" },
    //   { id: 8, name: t("countries.JO"), link: "#" },
    //   { id: 9, name: t("countries.LB"), link: "#" },
    //   { id: 10, name: t("countries.SY"), link: "#" },
    //   { id: 11, name: t("countries.IQ"), link: "#" },
    //   { id: 12, name: t("countries.YE"), link: "#" },
    //   { id: 13, name: t("countries.MA"), link: "#" },
    //   { id: 14, name: t("countries.DZ"), link: "#" },
    //   { id: 15, name: t("countries.TN"), link: "#" },
    //   { id: 16, name: t("countries.LY"), link: "#" },
    //   { id: 17, name: t("countries.SD"), link: "#" },
    //   { id: 18, name: t("countries.PS"), link: "#" },
    //   { id: 19, name: t("countries.SO"), link: "#" },
    //   { id: 20, name: t("countries.DJ"), link: "#" },
    //   { id: 21, name: t("countries.MR"), link: "#" },
    //   { id: 22, name: t("countries.KM"), link: "#" }
    // ];

     // Handle form input changes
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };
  return (
    <div className="block p-8 mx-auto text-right bg-white rounded-lg shadow-lg md:w-2/3 max-w-[700px]" dir={direction}>
      <CustomTitleWihBox title={t( "RegisterNewAccount")} />
      <form onSubmit={handleSubmit}>
        {/* Name */}
        <div className="mb-5">
          <CustomInput
            id="name"
            placeholder={t("FullName")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            icon={<UserIcon />}
            required
          />
        </div>

        {/* Email */}
        <div className="mb-5">
          <CustomInput
            id="email"
            type="email"
            placeholder={t("email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            icon={<EnvelopeIcon />}
            required
          />
        </div>

        <div className="flex flex-col-reverse items-center gap-3 lg:flex-row-reverse row">
          {/* Phone */}
          <div className="w-full lg:w-4/5">
            <CustomInput
              id="phone"
              type="tel"
              placeholder={t("PhoneNumber")}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              icon={<PhoneIcon />}
            />
          </div>
          {/* Phone Code & Selected Country ISO */}
          <div className="w-full lg:w-1/5">
            <div className="">
              {/* Arabic Label */}
    
              <PhoneCodeDropdown 
                  onSelect={(item) => setCountryCode(item)}
                  // value={countryCode}
              />
            </div>
            {/* <div className="w-1/2 mb-6 mr-2">
              <CustomInput
                id="country-iso"
                type="text"
                placeholder={t("ISOCountryCodeOptional")}
                value={selectedCountryIso}
                onChange={(e) => setSelectedCountryIso(e.target.value)}
                icon={<GlobeAltIcon />}
              />
            </div> */}
          </div>                
        </div>

        {/* Password & Confirm Password */}
        <div className="flex flex-col gap-3 my-3 lg:flex-row" dir={direction}>
          <div className="lg:w-1/2 ">
       
            <CustomPasswordInput
            id="password"
            type="password"
            // label={t("Password")}
            placeholder={t("Enteryourpassword")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            icon={<LockOpenIcon />} // Optional: Add an icon prop if supported
            required
          />
          </div>
          <div className="lg:w-1/2 ">
          <CustomPasswordInput
          id="confirm-password"
          type="password"
          // label={t("Password")}
          placeholder={t("confirmyourpassword")}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          icon={<LockOpenIcon />} // Optional: Add an icon prop if supported
          required
        />
        
          

          </div>
        </div>

        {/* Box Number */}
        {/* <div className="mb-6">
          <CustomInput
            id="box-number"
            type="text"
            placeholder={t("BoxNumber")}
            value={boxNumber}
            onChange={(e) => setBoxNumber(e.target.value)}
            icon={<HashtagIcon />}
            required
          />
        </div> */}

        {/* Country Information
        <div className="mb-6">
          <CustomInput
            id="country"
            type="text"
            placeholder={t("country")}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            icon={<GlobeAltIcon />}
          />
        </div> */}
         {/* Country Dropdown */}
         <div className="mb-4 ">
          {/* <CustomDropdown
            menuItems={menuItemsLocationTo}
            menuList={menuListLocationTo}
            textColor="custom-gray"
            // value={country}
            // placeholder={t("country")}

            onSelect={(item) =>
              setCountry({ country: item.name }) // Update selected country
            }
          /> */}
          {/* <CountryDropdown 
            onSelect={(item) => {
              // console.log("Selected Country:", item); // للتأكد من أن `item` يحتوي على النص الصحيح
              setCountry(item); // تخزين اسم الدولة مباشرةً
            }} 
          /> */}
          <CountryDropdown 
            onSelect={(item) => {
              setCountry(item);
            }} 
          />

        </div>

        

        {/* Submit Button */}
        <div className="flex justify-center mb-6">
          <button
            type="submit"
            className="block w-full px-6 py-3 text-base font-medium text-black uppercase transition duration-150 ease-in-out rounded-lg shadow-lg bg-custom-yellow hover:bg-primary-600 focus:outline-none"
          >
            {t("Register")}
          </button>
        </div>

        {/* Navigation to Login */}
        <p className="text-sm text-center text-gray-600">
        {t("AlreadyHaveAccount")}{" "}
        <div
  onClick={() => (window.location.href = "/LoginPage")}
  className="text-blue-600 cursor-pointer hover:underline"
>
  {t("LoginHere")}
</div>
        </p>
      </form>
    </div>
  );
}
