import React from "react";

const CustomInput = ({ placeholder, textColor, onChange, value ,title}) => {
  return (
    <div className="relative w-full ">
<h4
  className="mb-2 text-lg text-gray-800"
  dir=""
>         {title}
        </h4>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`w-full p-2 border border-gray-300 rounded-md bg-white 
          bg-transparent text-${textColor}  focus:outline-none 
          focus:ring-2 focus:ring-gray-400`}
        dir="" // Enable RTL direction for Arabic style
      />
    </div>
  );
};

export default CustomInput;
