import React from 'react';
import Header from '../component/sharedComponents/header';
import FooterUser from '../component/sharedComponents/footer';
import ContainerCookiesPolicy from '../component/cookiesPolicy/containerCookiesPolicy';
import CustomIntroSection from '../component/explainBuyPage/customIntroSection';
import { useTranslation } from 'react-i18next';
const  CookiesPolicyPage =()=>{
    const {t} = useTranslation();
    return (
        <div>
         <Header/>
        <div className='mt-[55px] md:mt-[54px] lg:mt-[75px]'>
            <CustomIntroSection
            title={t("privacySection.cookiePolicy")}
            />
        </div>
        <ContainerCookiesPolicy/>
        <FooterUser/>
        </div>
    )
}
export default CookiesPolicyPage;