import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BellIcon } from "@heroicons/react/24/outline";
// import { getNotifications, editNotificationToRead, deleteNotification } from "../../redux/reducer/notificationReducer"; // Import your thunks
import { useTranslation } from "react-i18next";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid"; // Import close icon

// Notification button component
const NotificationButton = () => {
  const iconSize = "lg:w-7 w-6 lg:h-7 h-6"; // Adjust size as needed

  // Get notifications from Redux store
  const { notifications = [] } = useSelector((state) => state.notifications || {});

  // Notification count from the length of the notifications array
  const notificationCount = notifications.length;

  return (
    <div className="relative inline-block">
      <button>
        <BellIcon className={`${iconSize} text-white duration-300 hover:text-custom-yellow`} />
      </button>
      {/* Notification badge */}
      {notificationCount > 0 && (
        <span
          className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 rounded-full"
        >
          {notificationCount}
        </span>
      )}
    </div>
  );
};

// Notification list component
const NotificatioList = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Get notifications from Redux store
  const { notifications = [], loading = false, error = null } = useSelector(
    (state) => state.notifications || {}
  );

  // Fetch notifications when component mounts
  useEffect(() => {
    // dispatch(getNotifications()); // Dispatch the thunk to fetch notifications
  }, [dispatch]);

  // Handle marking a notification as read
  const handleMarkAsRead = (notiId) => {
    // dispatch(editNotificationToRead(notiId));
  };

  // Handle deleting a notification
  const handleDeleteNotification = (notiId) => {
    // dispatch(deleteNotification(notiId));
  };
 const [direction, setDirection] = useState(
        localStorage.getItem("language") === "en" ? "rtl" : "ltr"
      );
useEffect(() => {
        const handleStorageChange = () => {
          setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
        };
      
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
      }, []);
      

      useEffect(() => {
        const language = localStorage.getItem("language");
        setDirection(language === "ar" ? "rtl" : "ltr");
      }, [localStorage.getItem("language")]);
            
            
  return (
    <div style={{marginLeft:0}}>
      {/* Notification Button with Menu */}
      <Menu as="div" className="relative inline-block mt-2">
        <MenuButton>
          <NotificationButton />
        </MenuButton>
        {/* Notification List Dropdown */}
        <MenuItems className="absolute right-[-120px] mt-2 w-60 bg-white text-black shadow-lg rounded-lg z-10">
          <div className="p-4" dir={direction === "ltr" ? "ltr" : "rtl"}>
            <h3 className="text-xs font-semibold md:text-lg">
              {t("notifications.title")}
            </h3>
            {loading && <p>{t("notifications.loading")}</p>}
            {error && <p className="text-red-500">Error: {error}</p>}
            <ul className="mt-2 space-y-2 text-black">
              {notifications.length > 0 ? (
                notifications.map((notification) => (
                  <MenuItem
                    key={notification._id}
                    className="flex items-center justify-between p-2 text-xs text-black rounded-md shadow-sm cursor-pointer md:text-sm hover:bg-gray-100"
                    as="li"
                  >
                    {/* Notification Description */}
                    <span
                      onClick={() => handleMarkAsRead(notification._id)}
                      className={`${notification.read ? "text-gray-500" : "text-black"}`}
                    >
                      {i18n.language === "ar"
                        ? notification.description.ar
                        : notification.description.en}
                    </span>
                    {/* Delete Icon */}
                    <XMarkIcon
                      className="w-4 h-4 text-gray-500 cursor-pointer hover:text-red-500"
                      onClick={() => handleDeleteNotification(notification._id)}
                    />
                  </MenuItem>
                ))
              ) : (
                <li className="text-gray-500">
                  {t("notifications.noNewNotifications")}
                </li>
              )}
            </ul>
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
};

export default NotificatioList;
