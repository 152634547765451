import React, { useState ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/reducer/authReducer";
import CustomTitleWihBox from "../sharedComponents/customTitleWithBox";
import { useTranslation } from "react-i18next";
import { LockOpenIcon } from "@heroicons/react/24/outline";
import CustomPasswordInput from "../accountUser/customPasswordInput";

export default function ResetPasswordForm() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector((state) => state.auth);
  const {t} = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    dispatch(resetPassword({ token, password }));
  };
      const [direction, setDirection] = useState(
        localStorage.getItem("language") === "en" ? "rtl" : "ltr"
      );
      useEffect(() => {
        const handleStorageChange = () => {
          setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
        };
      
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
      }, []);
      

      useEffect(() => {
        const language = localStorage.getItem("language");
        setDirection(language === "ar" ? "rtl" : "ltr");
      }, [localStorage.getItem("language")]);
            
            
  return (
    <div className="block  p-8 mx-auto bg-white rounded-lg shadow-lg md:w-2/3 max-w-[700px]" dir={direction}>
      <CustomTitleWihBox title={t("ResetPasswordTitle")} />
      <form onSubmit={handleSubmit} className="">
        {/* <div className="mb-4">
          <label htmlFor="token" className="block text-sm font-medium text-gray-700">
            Token
          </label>
          <input
            id="token"
            type="text"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border rounded"
            required
          />
        </div> */}
        <div className="mb-4">
          {/* <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            New Password
          </label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border rounded"
            required
          /> */}
          <CustomPasswordInput
            id="password"
            // label={t("Password")}
            placeholder={t("NewPassword")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            icon={<LockOpenIcon />} // Optional: Add an icon prop if supported
            required
          />
        </div>
        <div className="mb-4">
          {/* <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
            Confirm Password
          </label>
          <input
            id="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border rounded"
            required
          /> */}
          <CustomPasswordInput
            id="confirmPassword"
            // label={t("Password")}
            placeholder={t("ConfirmPassword")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            icon={<LockOpenIcon />} // Optional: Add an icon prop if supported
            required
          />
        </div>
        <button
          type="submit"
          className="block w-full px-6 py-3 text-base font-medium text-black uppercase transition duration-150 ease-in-out rounded-lg shadow-lg bg-custom-yellow hover:bg-primary-600 focus:outline-none"
          disabled={loading}
        >
          {loading ? "Resetting..." : t("ResetPasswordTitle")}
        </button>
      </form>
      {message && <p className="text-green-600 mt-4">{message}</p>}
      {error && <p className="text-red-600 mt-4">{error}</p>}
    </div>
  );
}
