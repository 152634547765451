import React from 'react';
import { useTranslation } from 'react-i18next';
import { MegaphoneIcon } from '@heroicons/react/24/outline';
import CustomTitleTerms from '../termsAndConditions/customTitleTerms';
import CustomTermPoints from '../termsAndConditions/customTermPoints';
import CustomTextingCard from '../termsAndConditions/customCard';

const QualityControlAndShipmentPhotography = () => {
  const { t, i18n } = useTranslation();

  // Check if the current language is Arabic
  const isRTL = i18n.language === 'ar';

  return (
    <div
      className={`py-3 px-3 md:py-7 lg:px-40  ${isRTL ? 'rtl' : 'ltr'}`} // Set direction
      dir={isRTL ? 'rtl' : 'ltr'} // Add dir attribute for accessibility
    >
      <div
        className={` ${
          isRTL ? 'text-right' : 'text-left'
        }  px-5 lg:px-20 shadow-lg py-10 lg:py-20 space-y-2`}
      >
        {/* Quality Control and Shipment Photography */}
        <CustomTitleTerms title={t('qualityControlTitle')} />

        {/* Quality Inspection Service */}
        <CustomTitleTerms title={t('qualityCheckTitle')} />

        {/* Quality Points */}
        {t('qualityPoints', { returnObjects: true }).map((point, index) => (
          <CustomTermPoints key={index} title={point} />
        ))}

        {/* Announcement */}
        <CustomTextingCard
          Icon={MegaphoneIcon}
          title={t('announcement')}
          subTitle={t('announcementDetails')}
        />
      </div>
    </div>
  );
};

export default QualityControlAndShipmentPhotography;
