import React from "react";

const CustomTitleTerms =({title})=>{
    return(
<h2 className="text-xl font-semibold text-black lg:text-3xl">
    {title}
</h2>
        
    )
}

export default CustomTitleTerms;