import React from "react";
import { PencilSquareIcon, CheckIcon, XCircleIcon ,DocumentArrowUpIcon} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

const CustomTitleAccount = ({ onClick, onExit, title, icon, isEditing }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between mt-4">
      <div className="flex">
        {icon && <span className="pointer-events-none">{icon}</span>}
        <h2 className="text-lg font-semibold text-black md:text-2xl lg:text-3xl">
          {title}
        </h2>
      </div>

      <div className="flex gap-3 my-3">
        {/* {isEditing && (
          <button
            onClick={onExit}
            className="flex items-center gap-2 px-2 py-2 text-sm font-bold text-black rounded-lg "
          >
            <span>{t("Exit")}</span>
            <XCircleIcon className="w-4 h-4 text-black" />
          </button>
        )} */}

        <button
          onClick={onClick}
          className={`flex items-center gap-2 px-2 py-2 text-sm font-medium rounded-lg  ${
            isEditing ? "text-black " : "text-black bg-transparent"
          }`}
        >
          <span>{isEditing ? t("Save") : t("Edit")}</span>
          {isEditing ? (
            <DocumentArrowUpIcon  className="w-4 h-4 text-black" />
          ) : (
            <PencilSquareIcon className="w-4 h-4 text-black" />
          )}
        </button>
      </div>
    </div>
  );
};

export default CustomTitleAccount;
  