import React, { useState } from "react";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/outline";

const CustomCardCount = ({ title, subTitle, count }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="flex flex-col items-center justify-center p-4 text-center transition-all duration-300 cursor-pointer"
    >
      {/* Chevron Icon */}
      <div className="relative flex justify-center w-full mb-2">
        {isHovered && (
          <ChevronDoubleDownIcon className="w-6 h-6 text-black absolute top-[-20px] transition-opacity duration-300" />
        )}
      </div>

      {/* Title */}
      <h2
    className="text-xs font-semibold text-black md:text-lg"
        
      >
        {title}
      </h2>

      {/* Count Box */}
      <div
        className={`px-2 md:px-4 mt-2 inline-block w-full py-2 rounded-md transition-all duration-300 ${
          isHovered ? "bg-black text-white" : "bg-custom-yellow text-black"
        }`}
      >
        <h2 className="text-xs md:text-lg">{count}</h2>
      </div>

      {/* Subtitle Box */}
      <div
        className="px-4 mt-2 inline-block w-full h-[30px] md:h-[60px] bg-white text-black rounded-md"
      >
        <h2 className="text-xs md:text-lg">{subTitle}</h2>
      </div>
    </div>
  );
};

export default CustomCardCount;
