import React,{useState,useEffect ,forwardRef } from 'react';
import CustomLines from './customLines';
import CustomBigText from '../sharedComponents/customBigText';
import CustomCardImportantServices from './customCardImportantServices';
import imageOne from '../../assets/images/imageoneimp.png';
import imageTwo from '../../assets/images/imagetwoimp.png';
import imageThree from '../../assets/images/imagethreeimp.png';
import { useTranslation } from 'react-i18next';
import CustomTitle from '../sharedComponents/customTitle';
const ImportantServices = forwardRef((props, ref) => {
  const {t} =useTranslation();
  const [direction, setDirection] = useState(
    localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
  );
  
  useEffect(() => {
    const handleStorageChange = () => {
      setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
    };
  
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);
  
  useEffect(() => {
    const language = localStorage.getItem("language");
    setDirection(language === "ar" ? "rtl" : "ltr");
  }, [localStorage.getItem("language")]); 
  
  return (
    <div className='' dir={direction} ref={ref} id="important-services">  
      <div className="flex flex-col items-center justify-center text-center max-container ">
        <div className='relative pt-5 pb-10 max-container'>
          <CustomTitle
            title={t('importantServices.title')}
            />
        </div>
        {/* <CustomBigText title={t('importantServices.title')} />     */}
          <p className="mt-4 text-gray-700">
          {t('importantServices.description')}   
            </p>
        <div className="flex flex-col items-center w-full gap-6 px-3 mt-5 mb-10 lg:flex-row">
          <div className="w-full mb-2 cursor-pointer sm:w-1/2 lg:w-1/3">
            <a href='/newStores'>
              <CustomCardImportantServices image={imageOne}   title={t('importantServices.card1Title')}/>
            </a>
          </div>
          <div className="w-full mb-2 cursor-pointer sm:w-1/2 lg:w-1/3">
            <a href='/newStores'>
            <CustomCardImportantServices image={imageTwo} title={t('importantServices.card2Title')} />
            </a>
          </div>
          <div className="w-full mb-2 cursor-pointer sm:w-1/2 lg:w-1/3">
            <a href='/newStores'>
            <CustomCardImportantServices image={imageThree} title={t('importantServices.card3Title')}/>
            </a>
          </div>
        </div>

      </div>
    </div>
  );
});

export default ImportantServices;
