import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { createShopCartItem } from "../../redux/reducer/shopCartReducer";
import MessageAlert from "../sharedComponents/messageAlert";
import copyImageBackground from '../../assets/images/cop.png';
import CustomInput from "../auth/CustomInput";
import { useTranslation } from "react-i18next";
const CopyLink = () => {
  const [quantity, setQuantity] = useState(1);
  const [productLink, setProductLink] = useState("");
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null); // "success" or "error"
  const userId = useSelector((state) => state.auth.user?.id || localStorage.getItem("userId"));
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
   const {t} = useTranslation();

  useEffect(() => {
    if (message) {
      setShowAlert(true);
      // Hide the alert after 1 second
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 1000);

      // Clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [message]);
  // Handle quantity increment and decrement
  const handleQuantityChange = (type) => {
    setQuantity((prev) => (type === "increment" ? prev + 1 : Math.max(1, prev - 1)));
  };

  // Handle form submission to create a cart item
  const handleAddToCart = () => {
    if (productLink.trim()) {
      const data = {
        name: "Product Name", // Placeholder, adjust based on your backend's logic
        productLink,
        productSize: "N/A",
        productCount: quantity,
        additionalInfo: "Default Info",
        status: "Waiting To Submit Order",
        images: [],
        Region: "Turkey",
        price: 0,
        DShippingFees: 0,
        userId,
      };

      dispatch(createShopCartItem(data))
        .unwrap()
        .then(() => {
          setMessageType("success");
          setMessage("تم إضافة المنتج إلى السلة بنجاح!");
          setProductLink(""); // Clear input field
          setQuantity(1); // Reset quantity
        })
        .catch((error) => {
          setMessageType("error");
          setMessage(`فشل في إضافة المنتج إلى السلة: ${error.message}`);
        });
    } else {
      setMessageType("error");
      setMessage("يرجى إدخال رابط صالح للمنتج.");
    }
  };

  return (
    <div
    className="relative flex items-center justify-center w-full bg-cover bg-center "
    >
    <img
    src={copyImageBackground}
    alt="Your Image Description"
    className="w-full h-full object-cover min-h-[50vh]"
  />
      <div className="absolute max-container inset-0 flex flex-col items-center justify-center w-full h-auto  p-5">
        <div className="max-w-lg w-full space-y-4">
          {/* Display MessageAlert */}
          {showAlert && <MessageAlert type={messageType} message={message} />}

          {/* <input
            type="text"
            placeholder="ضع رابط المنتج المراد شراؤه"
            className="w-full p-3 text-right bg-white text-gray-700 border rounded-md shadow-sm focus:ring focus:ring-yellow-400 focus:outline-none"
            value={productLink}
            onChange={(e) => setProductLink(e.target.value)}
          /> */}
          <CustomInput
              id="link"
              placeholder={t("Putthelink")}
              value={productLink}
              onChange={(e) => setProductLink(e.target.value)}
              required
              className="focus:ring focus:ring-yellow-400 focus:outline-none"
            />
          <div className="flex items-center justify-between mt-4">
          <button
              onClick={handleAddToCart}
              className="flex items-center justify-center px-6 py-3 text-black bg-custom-yellow rounded-md hover:bg-custom-yellow"
            >
              {t("addtocart")}
              <ShoppingCartIcon className="w-5 h-5 ms-2 text-black" />
            </button>
            <div className="flex items-center border rounded-md" dir="ltr">
              <button
                className="p-2 text-black bg-gray-200 hover:bg-gray-300"
                onClick={() => handleQuantityChange("decrement")}
              >
                -
              </button>
              <span className="px-4">{quantity}</span>
              <button
                className="p-2 text-black bg-custom-yellow hover:bg-custom-yellow"
                onClick={() => handleQuantityChange("increment")}
              >
                +
              </button>
            </div>

            
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyLink;
